import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-simpleprocess',
  templateUrl: './simpleprocess.component.html',
  styleUrls: ['./simpleprocess.component.css']
})
export class SimpleprocessComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  features= [
    {
      img:'assets/images/agency/blog/1.Draft Preparation_360_240_Website_Color_FINAL.gif',
      title:'Draft Preparation',
      subTitle:'As Simple As 1.2.3.',
      description:'Simply contact us with the quickest sign up; fill renting details in our web app it will prepare the draft for you automatically. Or you may also send your details over Whatsapp, Email, SMS or any other channel. Your convenience is our top priority, literally!'   
    },
    {
      img:'assets/images/agency/blog/2.schedule-your-appointment1_360_240-Website_Color.png',
      title:' Doorstep Appointment',
      subTitle:'As Simple As 1.2.3.',
      description:' Once both parties (Owner/s Tenant/s) review and approve the draft then schedule an appointment at mutually convenient place and time. Our executive will complete the identity validation process within few minutes.'   
    },
    {
      img:'assets/images/agency/blog/3.delivery_360_240_website_color.png',
      title:'Delivery',
      subTitle:'As Simple As 1.2.3.',
      description:'You will receive the registered copies within 2-3 working days of government department. We will email the soft copy of registered agreement, and, also on-request we can provide hard copies.'   
    }
  ] 
 
  
}
