
<app-home></app-home>

<app-simpleprocess></app-simpleprocess>

<app-rentactionformat></app-rentactionformat>
<app-calculator></app-calculator>
<app-actiontime></app-actiontime>
<app-whyvrentify></app-whyvrentify>
<app-happyclient></app-happyclient>
<app-review></app-review>
<app-faq></app-faq>
<app-blog-home></app-blog-home>
<app-registerrent></app-registerrent>


<!-- <router-outlet></router-outlet> -->


