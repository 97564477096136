// auth.guard.ts
import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { LoginServiceService } from './login-service.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private loginService: LoginServiceService,private router: Router) {}

  canActivate() {

    if (!this.loginService.isLoggedIn())
    {
       this.router.navigate(['login']);
       return false;
    }

    return true;
  }
}
