<!-- Its action time section start -->
<section class="yoga trainers screenshot " id="screenshot">
    <div class="container">
        <div class="row">
            <div class="col-md-8 offset-md-2">
                <div class="title title2">
                    <h3 class="">REGISTER YOUR RENT AGREEMENT</h3>
                    <h5 class="m-b-10 ">Save Time | Save Efforts | Save Money</h5>
                    <!-- <img src="assets/images/yoga-img/logo/wave-blue.png" alt="" class="img-fluid"> -->
                </div>
            </div>
        </div>
    </div>
    <div class="container">
       <div class="row md">
           <div col-lg-8 offset-lg-2  >
            <a href="#" class="cd"><input class="btn primary-btn btn-default radius-20" id="loginNow" type="button" value="LOGIN NOW" style="background-color: #FFA500;"></a> 
            <a href="#faq" class="pd1" ><input class="btn primary-btn btn-default radius-20 pd" id="Reqback" type="button"  value="LEAVE US A MESSAGE"style="background-color: #FFA500;"></a>
          
           </div>

       </div>
      </div>
  </section>
     <!--expert section end-->
