<section class="agency blog blog-bg" id="blog"
    [ngStyle]="{'background-image': 'url(assets/images/agency/blog/background.png)'}">
    <div class="container">
        <div class="row">
            <div class="col-md-6 offset-md-3">
                <div class="title">
                    <div class="main-title">
                        <h1 class="text-capitalize text-center" style="font-size:xxx-large;"><b> Blogs</b></h1>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <owl-carousel-o [options]="blogCarouselOptions">
                    <ng-container *ngFor="let post of posts | async">
                        <ng-template carouselSlide class="item">
                            <div class="blog-agency">
                                <div class="blog-contain" [owlRouterLink]="['/blogDetail' ,post.id]">
                                    <img src="{{post.MainImg}}" alt="{{post.Title}}" class="img-fluid">

                                    <div class="img-container center-content">
                                        <div class="center-content">
                                            <div class="blog-info">
                                                <div class="m-b-10">
                                                    <div class="center-text">
                                                        <i class="fa fa-clock-o m-r-10" aria-hidden="true"></i>
                                                        <h6 class="m-r-25 font-blog"> {{post.CDate}}</h6>
                                                        <i class="fa fa-user m-r-10" aria-hidden="true"></i>
                                                        <h6 class="font-blog">{{post.Name}}</h6>
                                                    </div>
                                                </div>

                                                <h5 class="blog-head font-600"> {{post.Title}}</h5>
                                                <p class="para2">{{post.BlogDescription}}</p>
                                                <div class="m-t-20"><a class="text-uppercase" target="_blank">Read
                                                        More</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>
<!-- blog section end-->