import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/observable';
import { Post } from '../post';
import { PostserviceService } from '../postservice.service';

@Component({
  selector: 'app-blog-home',
  templateUrl: './blog-home.component.html',
  styleUrls: ['./blog-home.component.css']
})
export class BlogHomeComponent implements OnInit {
  Title:string="Blog List"
  showData:any=[];
  postcollection:any={};
  newdata:any={};
  posts: Observable<Post[]>
  //posts:any=[]
;  constructor(private postservice:PostserviceService,private router:Router) { }
 

   ngOnInit(){
    // this.GetLimitedPost()
    
   
    this.posts=this.postservice.getPosts();
    console.log(this.posts)
  }
  delete(id:string)
  {
    this.postservice.delete(id);
  }
//   next(e){
// console.log(e)
//   }
 
blogCarouselOptions = {
    items: 8,
    margin: 80,
    autoHeight: true,
    nav: false,
    dots: false,
    autoplay: true,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true,
    responsive: {
        0: {
            items: 1,
            margin: 10
        },
        320:{
            items: 2,
            margin: 20
        },
        480:{
            items: 3,
            margin: 10
        },
      /*   576:{
            items: 4,
            margin: 0
        },
        768:{
            items: 6,
            margin: 30
        }, */
       /*  992:{
            items: 7,
            margin: 30
        },
        1200: {
            items: 8,
            margin: 50
        } */
    }
  }
}
