<!-- <app-home></app-home> -->
<!-- feature section-->
<section id="feature" class="saas1 service" id="process" style="margin-top:6rem;">
    <div class="about-chat">
        <div class="container" >
            <div class="row">
                <div class=" col-md-12 text-center">
                    <div class="title">
                        <!-- <img src="assets/images/saas1/title-logo.png" alt="title-logo" class="img-fluid"> -->
                            <div class="main-title">
                                <h2 class="borders text-center color" >
                                About Us
                                </h2><br>
                            
                            </div>
                            <hr style=" background-color:#00abb9 ;" >
                              <div class="sub-title">
                                <p>Vrentify is the fastest growing and a leading doorstep service provider for Maharashtra’s online registration mechanism, and the most trusted amongst citizens of India. <br><br>
                                We are technology enthusiasts and passion driven young individuals knitted closely with a mission to serve government initiatives in a customer-centric style. In an E-commerce era, we strongly believe that rapidly growing technologies and popular communication channels can simplify the complex and time-consuming government processes. <br><br>
                                Our missionary zeal has been acknowledged by the State Government of Maharashtra and awarded us with a responsibility to serve as an <b>“Authorized Service Provider for E-Registration” </b><br><br>
                                With assurance for flexible digital payment modes, complemented with strong doorstep network, it bolsters our support for “Digital India” initiative. We are an ardent admirer and supporter for state and central government initiatives that takes our country to greater heights.<br>
                                </p>
                                </div>
                    </div>
                    
                </div>    
            </div>
        </div>
    </div>
</section>

<!-- end feature section -->





