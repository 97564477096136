 

<!--footer start-->
  <footer class="saas2 footer2" id="contact" >
     <div class="container footer-padding">
        <div class="row">
            <div class="col-lg-2 col-md-6 col-sm-12"> <img src="assets/images/logo/vrentify_final.png" alt="" class="footer-logo"></div>
            <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Vrentify</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Company</h5>
                    <div>
                        <ul class="footer-lists">
                            <li class="">
                                <a routerLink="/about">About Us</a>
                            </li>
                            <!-- <li class="">
                                <a href="#home">Career</a>
                            </li> -->
                            <li class="">
                                <a routerLink="/term">Term and Conditions</a>
                            </li>
                            <!-- <li class="">
                                <a href="#calculator">Refund & Cancellation Policy</a>
                            </li> -->
                            <li class="">
                                <a routerLink="/disclaimer">Disclaimer</a>
                            <!-- </li>
                            <li class="">
                                <a href="#home">Cities</a>
                             </li>
                            <li class="">
                                <a href="#process">Privacy Policies</a>
                            </li> -->
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Contact Us</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Contact Us</h5>
                    <div>
                        <ul class="footer-lists">
                            <li class=""><i class="fa fa-map-marker" aria-hidden="true"></i> &nbsp;Doorstep Service at:
                                <a href="#home" style="text-decoration:none"><br>Mumbai | Pune | Nagpur | Nashik | Aurangabad</a>
                            </li>
                            <li class="">
                                <a href="#" ><i class="fa fa-phone" aria-hidden="true"></i> &nbsp;879-3333-921</a>
                            </li>
                            <!-- <li class="">
                                <a  href="https://mail.google.com/mail/?view=cm&amp;fs=1&amp;tf=1&amp;to=vrentifyemail18@gmail.com" target="_blank" ><i class="fa fa-envelope" aria-hidden="true"> </i>&nbsp; registration@Vrentify.com </a>
                            </li> -->
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Technology Partners</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Technology Partners</h5>
                    <div class="form-group">
                        <a href="https://www.oceansofttech.net/" class="text-white" target="_blank" >Ocean Software Technologies</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">RESOURCES</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">RESOURCES</h5>
                    <div class="form-group">
                        <a href="#agreementformat" class="text-white">Download Rent Agreement Format</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row rowpd">
          
            <div class="col-md-12">
                <div class="socials-lists">
                    <ul class="socials-horizontal justify-content-center">
                        <li>
                            <a href="https://www.pinterest.com/vrentify/" target="_blank"><i class="fa fa-pinterest center-content" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li>
                            <a href=" https://www.facebook.com/pg/vrentify/reviews/?ref=page_internal" target="_blank"> <i class="fa fa-facebook center-content" aria-hidden="true"></i></a>
                        </li>
                        <li>
                            <a href="https://twitter.com/@vrentify" target="_blank"  ><i class="fa fa-twitter center-content" aria-hidden="true"></i></a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/company/13374484/admin/" target="_blank"  ><i class="fa fa-linkedin center-content" aria-hidden="true"></i></a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/company/13374484/admin/" target="_blank" ><i class="fa fa-instagram center-content"  aria-hidden="true"></i></a>
                        </li>
                        <li>
                            <a href="https://www.youtube.com/channel/UCMLRirvbH3PYQwjqFoR3e3Q" target="_blank" ><i class="fa fa-youtube-play center-content" aria-hidden="true"></i>
                            </a>
                        </li>
                    </ul>
                </div>
        </div>
     </div>
     <div class="row"><div class="col-lg-4 offset-lg-4 ml text-white">
        All Right Reserved<a  href="https://mail.google.com/mail/?view=cm&amp;fs=1&amp;tf=1&amp;to=vrentifyemail18@gmail.com" class="text-white">@ Vrentify.com</a>
      </div> 
     </div>
     </div>
   </footer>
 <app-taptotop></app-taptotop>
<!--footer end-->
