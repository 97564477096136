import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from '../Service/main.service';

@Component({
  selector: 'app-rentactionformat',
  templateUrl: './rentactionformat.component.html',
  styleUrls: ['./rentactionformat.component.css']
})
export class RentactionformatComponent implements OnInit {
  @ViewChild('email') Email;
  ApiResult: any;
  sendemailid : any;
  form!:FormGroup;

  constructor(private mainService:MainService,private router:Router,private fb:FormBuilder) { }

  ngOnInit() { }
  SystemSendEmail_SelectEmail() {
    var Moobj: any = {};
    Moobj.Pid = '6ab0afa8-bc00-45c6-90cd-959b499d7617';
    this.mainService.TwoMethod(Moobj).then(value => {
      this.ApiResult = value;
      this.ApiResult = this.ApiResult.table;
      this.sendemailid = this.ApiResult[0].emailId
      console.log(JSON.stringify(this.sendemailid))
      localStorage.setItem("GetSendMail",this.sendemailid)
    });
  }
 
  Email_send() {
   var Moobj: any = {};
   Moobj.EmailSubject="For subscribe Us";
   this.sendemailid= localStorage.getItem("GetSendMail");
   Moobj.EmailTo =this.sendemailid;
   Moobj.PkSystemEmailSetting=4; 
   Moobj.message=(<HTMLInputElement>document.getElementById('email')).value
   this.mainService.emailMethod(Moobj).subscribe(value => {
     this.ApiResult = value;
     this.ApiResult =  this.ApiResult.table;
     console.log(value)
   });
   this.Email.nativeElement.value = ' ';
  }
 
}
