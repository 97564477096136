
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthrizationService } from '../Service/auth.service';
@Component({
  selector: 'app-loginf',
  templateUrl: './loginf.component.html',
  styleUrls: ['./loginf.component.css']
})
export class LoginfComponent implements OnInit {

  @ViewChild('f', { static: false }) loginForm: NgForm;
  errors: string;
  private IsSuccess: boolean;
  submitted = false;
  form: FormGroup;

  constructor(private router: Router, private fb: FormBuilder, public authservice: AuthrizationService) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required,]]
    });
  }

  login(form) {
    alert(form)
    this.authservice.login(this.form.value.email, this.form.value.password)
      .subscribe(
        result => {

          if (result.result == 1) {
            this.router.navigate(['/admin']);
          }
          else {
            alert("Login Fail")
          }
        },
        error => {
          alert("Login faild")
        });
  }
}