<!-- <app-home-title [title]="Title"></app-home-title> -->
<!-- Start News Area -->
<section class="offer-section  pt">
    <div class="container">
       
        <div class="row" >
            <div class="col-lg-4 col-md-6" *ngFor="let post of posts | async">
                <div class="news-item"  [routerLink]="['/blogdetails', post.id]" >
                    <div class="image">
                        <a>
                            <img src="{{post.MainImg}}" alt="{{post.Title}}" style="width: 100%;">
                        </a>
                    </div>

                    <div class="content" >
                        <ul>
                            <li>
                                {{post.CDate}}
                            </li>
                            <li>
                                <a  >RTD-Research</a>
                            </li>
                        </ul>

                        <h3>
                            <a >
                                {{post.Title}}
                            </a>
                        </h3>
                        >
                        <div class="news-btn" >
                            <a  class=" btn default-btn anchor ">Read More</a>
                        </div>
                    </div>
                </div>
            </div>
        
        </div>
    </div>
</section>
<!-- End News Area -->

