<!-- Its action time section start -->
<section class="yoga trainers screenshot bg-theme pd bg" id="screenshot">
    <div class="container">
        <div class="row">
            <div class="col-md-8 offset-md-2">
                <div class="title title2">
                    <h2 class="text-white">It's Action Time</h2>
                    <h5 class="m-b-10 text-white">Select Your Mode Communication</h5>
                    <!-- <img src="assets/images/yoga-img/logo/wave-blue.png" alt="" class="img-fluid"> -->
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <owl-carousel-o [options]="expertCarouselOptions" class="trainers-slider"> 
          <ng-container *ngFor="let expert of experts">
            <ng-template carouselSlide class="item">
               <a [href]='expert.href' target="_blank"> <img [src]="expert.img" alt="" class="img-fluid" ></a>
                <!-- <div class="text-center trainers-info">
                    <div class="socials-lists">
                        <ul class="socials-horizontal justify-content-center">
                            <li><a  href=" https://www.facebook.com/pg/vrentify/reviews/?ref=page_internal" target="_blank" ><i class="fa fa-facebook center-content" aria-hidden="true"></i></a></li>
                            <li><a  href="https://twitter.com/@vrentify" target="_blank" ><i  class="fa fa-twitter center-content" aria-hidden="true"></i></a></li>
                            <li><a  href="https://www.linkedin.com/company/13374484/admin/" target="_blank" ><i class="fa fa-linkedin center-content" aria-hidden="true"></i></a></li>
                            <li><a  href="https://www.linkedin.com/company/13374484/admin/" target="_blank" ><i class="fa fa-instagram center-content" aria-hidden="true"></i></a></li>
                        </ul>
                    </div>
                </div> -->
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
  </section>
     <!--expert section end-->
