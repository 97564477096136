import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BlogComponent } from './blog/blog.component';
import { NavComponent } from './nav/nav.component';


const routes: Routes = [
  {
    path:"dashboard",
    component: NavComponent,
    children: [
      { path:'blog',component:BlogComponent},
     
    ]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class DashboardRoutingModule { 

}
