
<app-header></app-header>
<router-outlet (activate)="onActive()"></router-outlet>
<!-- <div>
    <a href="#navbarTogglerDemo02">
        <img src="assets/images/scrolltop.png">
    </a>
</div> -->

<app-footer></app-footer> 
