<!--accordion3 section start-->
 
<section class="saas1 accordion faq testimonial-bg sec" id="faq">
    <div class="container sec">
        <div class="row  ">
            <div class="col-lg-12">
                <div class="title title2 title-inner">
                    <div class="main-title">
                        <h2 class="font-primary borders text-center main-text m-b-0"><span class="text-uppercase">Frequently Asked Questions</span> </h2>
                        <br><br>
                    </div>
                    <div class="sub-title ">
                        <h5 class="sub">Our panel of experts have collated most important questions for you, categorized them for
                            your ease of access.</h5><br><h5>If you still have query, feel free to mail us at <a style="color:#0d00c9;"
                                href="https://mail.google.com/mail/?view=cm&amp;fs=1&amp;tf=1&amp;to=vrentifyemail18@gmail.com">registration@vrentify.com</a> or
                           <span class="text-center">simply call at</span>  <a style="color:#0003c9;" href="tel:+087933 33921">879-3333-921.</a></h5>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 ">
                <div class="textcol"><h3>RentAgreement</h3></div>
                <div class="faq-block">
                    <div class="w-100">  
                      <ngb-accordion [closeOthers]="true" class="accordion faq" activeIds="static-1">
                        <ngb-panel id="static-1" class="card">
                            <ng-template ngbPanelTitle class="card-header">
                                <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                aria-expanded="false">
                               <a>
                                   <div class="fa fa-angle-right rotate"></div>
                               </a>
                                    Which documents are required for online rent agreement registration ?
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent class="card-body">
                                Every party or entity (like Owner/s, Tenant/s and Witnesses) MUST have UID- AADHAR and PAN
                                card. PAN card is optional for Witnesses.
                           
                               <a  routerLink="/rentformat"  id="readme">>>READ
                                  MORE</a>
                            </ng-template>
                          </ngb-panel>
                          <ngb-panel id="static-3" class="card">
                            <ng-template ngbPanelTitle class="card-header">
                                <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                aria-expanded="false">
                               <a>
                                   <div class="fa fa-angle-right rotate"></div>
                               </a>
                               Can I add multiple owners as well as multiple tenants in my agreement?
                           </div>
                            </ng-template>
                            <ng-template ngbPanelContent class="card-body">
                                <p>Yes! In E-registration mechanism you can add multiple owners as well as
                                    tenants. Provided all must have UID AADHAR and PAN card.
                                    <!-- <a [routerLink]="['/faqs']" id="readme">>>READ  MORE</a> -->
                                </p>
                            </ng-template>
                          </ngb-panel>
                            <ngb-panel id="static-4" class="card">
                                <ng-template ngbPanelTitle class="card-header">
                                    <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                    aria-expanded="false">
                                   <a>
                                       <div class="fa fa-angle-right rotate"></div>
                                   </a>
                                   How shall I get my registered copy? Whether as a soft copy or hard copy?
                               </div>
                                </ng-template>
                                <ng-template ngbPanelContent class="card-body">
                                  You can get it in both formats. We send soft copy via mail as default.
                                  On-request we send hard-copies too, but it will take certain days based upon
                                  your location.
                                  <!-- <a [routerLink]="['/faqs']" id="readme">>>READ  MORE</a> -->
                                </ng-template>
                              </ngb-panel>
                               <ngb-panel id="static-5" class="card">
                                <ng-template ngbPanelTitle class="card-header">
                                    <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                    aria-expanded="false">
                                   <a>
                                       <div class="fa fa-angle-right rotate"></div>
                                   </a>
                                   Within how many days I will receive my registered agreement?
                               </div>
                                </ng-template>
                                <ng-template ngbPanelContent class="card-body">
                                    <p>After submission, department reviews, verifies and register. Thus, ideally
                                        you will be receiving it within 2-4 business working days of government
                                        department,
                                        <!-- <a [routerLink]="['/faqs']" id="readme">>>READ  MORE</a> -->
                                    </p>
                                </ng-template>
                              </ngb-panel>
                              <ngb-panel id="static-6" class="card">
                                <ng-template ngbPanelTitle class="card-header">
                                    <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                    aria-expanded="false">
                                   <a>
                                       <div class="fa fa-angle-right rotate"></div>
                                   </a>
                                   How many witnesses are required for online rent agreement registration?
                               </div>
                                </ng-template>
                                <ng-template ngbPanelContent class="card-body">
                                    <p>In total only two witnesses are required. We recommend both witnesses should
                                        be provided by you and both parties should mutually understand who will be
                                        providing same. Almost anyone can execute as witness, person MUST have
                                        AADHAR card number with him/her.
                                        <!-- <a [routerLink]="['/faqs']" id="readme">>>READ  MORE</a> -->
                                    </p>
                                </ng-template>
                              </ngb-panel>
                              <ngb-panel id="static-7" class="card">
                                <ng-template ngbPanelTitle class="card-header">
                                    <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                    aria-expanded="false">
                                   <a>
                                       <div class="fa fa-angle-right rotate"></div>
                                   </a>
                                   Do I need to pay stamp duty and registration fees to Vrentify.com?
                               </div>
                                </ng-template>
                                <ng-template ngbPanelContent class="card-body">
                                    <p>For the submission of E-registration GRN number is required which you can
                                        generate by directly paying it to government of Maharashtra. Or, to
                                        facilitate further we can do it on your behalf at FREE OF COST, without any
                                        commission. It’s your choice ultimately.
  
                                        <!-- <a [routerLink]="['/faqs']" id="readme">>>READ  MORE</a> -->
                                    </p>
                                </ng-template>
                              </ngb-panel>
                              <ngb-panel id="static-10" class="card">
                                <ng-template ngbPanelTitle class="card-header">
                                    <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                    aria-expanded="false">
                                   <a>
                                       <div class="fa fa-angle-right rotate"></div>
                                   </a>
                                   What is the process of online rent agreement registration?
                               </div>
                                </ng-template>
                
                                <ng-template ngbPanelContent class="card-body">
                                    <ul>
                                        <li>Simply submit your information</li>
                                        <li> Verify and Approve your draft</li>
                                        <li>Schedule an appointment</li>
                                        <li>This process takes hardly few minutes of your valuable time. And, the
                                            agreement once submitted to department will be received within 2-4
                                            working days.</li>
                                        <li> <strong> Note:</strong> Across Maharashtra, Sub-Registrar offices
                                            remains closed on 2nd and 4th Saturdays of every month.</li>
                                        then rent agreement can surely be registered, irrespective of the purpose of
                                        renting.
                                        <!-- <a  [routerLink]="['faqs']" id="readme">>>READ  MORE</a> -->
                                    </ul>
                                </ng-template>
                              </ngb-panel>
                              <ngb-panel id="static-11" class="card" style="text-align:center ;" >
                                <ng-template ngbPanelTitle class="card-header" >
                        
                                    <div class="col-lg-1 offset-lg-3" >
                                            <a routerLink="/rentformat" target="_blank" >
                                                <input class="btn primary-btn btn-default radius-20 mg" id="headbut" style="background-color: #FFA500;" type="button" value="READ  MORE" ></a>
                                        </div>
                                        
                                </ng-template>
                            </ngb-panel>
                      </ngb-accordion>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 sec">
                <div class="register-page section-b-space">
  
                    <h3 class="h">Leave Us a Message</h3>
                    <p class="text-sub"></p>
                    <div class="theme-card">
                        <form class="theme-form" [formGroup]="form" (ngSubmit)="Email_send()">
                            <input type="text" class="form-control" id="name" placeholder=" Name"
                                   required="" formControlName="Name" >
                            <input type="email" class="form-control" id="email" placeholder="Email"
                                   required="" formControlName="Email">
                            <input type="tel" class="form-control" id="tel" placeholder="Mobile Number"required="" formControlName="phone">
                            <div class="form-group">
                                <textarea class="form-control" rows="5" placeholder="Your Message" formControlName="Message"></textarea>
                            </div>
                            <a class="btn primary-btn  btn-default radius-20 pgl" style="background-color: #FFA500;" (click)="Email_send() " >Submit</a>
                        </form>
                        <div class="text-center ">
                            <h4 class="text">OR</h4>
                            <h6 style="text-transform: capitalize;"> You should use it.....</h6> 
                        </div>
                        <div class="text-center trainers-info">
                            <div class="socials-lists">
                                <ul class="socials-horizontal justify-content-center">
                                    <li><a href="http://api.whatsapp.com/send?phone=918793333921&text=Hi" target="_blank" ><i class="fa fa-whatsapp  center-content"  aria-hidden="true"></i></a></li>
                                    <li><a   href="https://mail.google.com/mail/?view=cm&amp;fs=1&amp;tf=1&amp;to=vrentifyemail18@gmail.com" target="_blank" ><i  class="fa fa-envelope-open center-content" aria-hidden="true"></i></a></li>
                                    <li><a  href="#login" target="_blank" ><i class="fa fa-map-marker center-content" aria-hidden="true"></i></a></li>
                                    <li><a  href="sms://+918793333921?body=I%27m%20interested%20in%20your%20product.%20Please%20contact%20me." target="_blank" ><i class="fa fa-envelope center-content" aria-hidden="true"></i></a></li>
                                    <li><a  href="tel:+087933 33921" target="_blank" ><i class="fa fa-phone center-content" aria-hidden="true"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
  
                </div>
            </div>
        </div>
    </div>
  </section>
  
  <!--accordion section end-->
  

