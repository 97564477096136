import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LayoutComponent } from './layout/layout.component';
import { HomeComponent } from './home/home.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './shared/components/navigation/menu/menu.component';
import { SimpleprocessComponent } from './simpleprocess/simpleprocess.component';
import { RentactionformatComponent } from './rentactionformat/rentactionformat.component';
import { CalculatorComponent } from './calculator/calculator.component';
import { WhyvrentifyComponent } from './whyvrentify/whyvrentify.component';
import { HappyclientComponent } from './happyclient/happyclient.component';
import { FaqComponent } from './faq/faq.component';

import { RentdocformatComponent } from './rentdocformat/rentdocformat.component';
import { ActiontimeComponent } from './actiontime/actiontime.component';
import { MainServiceService } from './Service/main-service.service';
import { LoginServiceService } from './login/login-service.service';
import { LoginfComponent } from './loginf/loginf.component';
import { ConfigService } from './login/config.service';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';

import{AuthGuard} from './login/auth.guard';
import { from } from 'rxjs';


/* import {firebaseConfig} from "src/app/firebase.config"; */
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth'
import { PostserviceService } from './postservice.service';
import { HttpModule } from '@angular/http';
import { TaptotopComponent } from './taptotop/taptotop.component';
import { BlogHomeComponent } from './blog-home/blog-home.component';
import { ReviewComponent } from './review/review.component';
import { RegisterrentComponent } from './registerrent/registerrent.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { TermComponent } from './term/term.component';
// import { BlogInsertAdminComponent } from './blog-insert-admin/blog-insert-admin.component';
import { CKEditorModule } from 'ngx-ckeditor';
// import { AuthGuard } from './Service/auth-guard.service';
import { AuthrizationService } from './Service/auth.service';
import { MainService } from './Service/main.service';
import { ApiConfigService } from './Service/api-config.service';
import { CUSTOM_ELEMENTS_SCHEMA, } from '@angular/core';
import { DashboardRoutingModule } from './dashboard/dashboard-routing.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { BlogDetailsComponent } from './blog-details/blog-details.component';
import { BlogListComponent } from './blog-list/blog-list.component';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LayoutComponent,
    HomeComponent,
    MenuComponent,
    SimpleprocessComponent,
    RentactionformatComponent,
    CalculatorComponent,
    WhyvrentifyComponent,
    HappyclientComponent,
    FaqComponent,
    BlogHomeComponent,
    LoginfComponent,
    ActiontimeComponent,
    PagenotfoundComponent,
    RentdocformatComponent,
    TaptotopComponent,
    ReviewComponent,
    RegisterrentComponent,
    AboutusComponent,
    DisclaimerComponent,
    TermComponent,
    // BlogInsertAdminComponent,
    BlogDetailsComponent,
    BlogListComponent,



  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpModule,
    HttpClientModule,
    NgbModule,
    ReactiveFormsModule,
    CarouselModule,
    RouterModule,
    AppRoutingModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFirestoreModule, AngularFireAuthModule,
    CKEditorModule,
    DashboardRoutingModule,
    DashboardModule

  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [MainServiceService,AuthGuard, LoginServiceService, ConfigService, PostserviceService, AuthrizationService, MainService,
     ApiConfigService

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
