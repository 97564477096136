import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ActiontimeComponent } from './actiontime/actiontime.component';
import { BlogDetailsComponent } from './blog-details/blog-details.component';

import { BlogHomeComponent } from './blog-home/blog-home.component';
import { BlogListComponent } from './blog-list/blog-list.component';
// import { BlogInsertAdminComponent } from './blog-insert-admin/blog-insert-admin.component';
import { CalculatorComponent } from './calculator/calculator.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { FaqComponent } from './faq/faq.component';
import { HappyclientComponent } from './happyclient/happyclient.component';

import { HomeComponent } from './home/home.component';
import { LayoutComponent } from './layout/layout.component';
import { AuthGuard } from './login/auth.guard';
import { LoginfComponent } from './loginf/loginf.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { RegisterrentComponent } from './registerrent/registerrent.component';
import { RentdocformatComponent } from './rentdocformat/rentdocformat.component';

import { SimpleprocessComponent } from './simpleprocess/simpleprocess.component';
import { TermComponent } from './term/term.component';

import { WhyvrentifyComponent } from './whyvrentify/whyvrentify.component';


const routes: Routes = [
  {
    path: '', component: LayoutComponent,
    children: [
      { path: '', component: HomeComponent },
      { path: 'home', component: HomeComponent },
      // {path:'aboutus', component:AboutusComponent},
      // {path:'disclaimer',component:DisclaimerComponent},
      { path: 'process', component: SimpleprocessComponent },
      { path: 'calculator', component: CalculatorComponent },
      { path: 'services', component: WhyvrentifyComponent },
      { path: 'clients', component: HappyclientComponent },
      { path: 'blog', component: BlogHomeComponent },
      { path: 'bloglist', component: BlogListComponent },
      { path: 'faq', component: FaqComponent },

      // {path:'bloginsertadmin', component:BlogInsertAdminComponent},//add 
    ]
  },
  { path: 'actionservices', component: ActiontimeComponent },
  { path: 'login', component: LoginfComponent },
  { path: 'rentformat', component: RentdocformatComponent },
  { path: 'registerrentagreement', component: RegisterrentComponent },
  { path: 'about', component: AboutusComponent },
  { path: 'disclaimer', component: DisclaimerComponent },
  { path: 'term', component: TermComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'admin', canActivate: [AuthGuard], loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },

  { path: 'blogDetail/:id', component: BlogDetailsComponent },
  { path: 'blog', component: BlogHomeComponent },
  { path: '**', component: PagenotfoundComponent },

];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
