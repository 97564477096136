import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { Post } from '../post';
// import { PostserviceService } from '../postservice.service';
import { blogDetailDB } from '../shared/data/blog/blog-detail/blog-detail';
import { Observable } from 'rxjs/observable';
import { AuthenticationService } from 'src/app/shared/authentication.service';
import { Post } from 'src/app/shared/post';
import { PostserviceService } from 'src/app/shared/postservice.service';
@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.css']
})
export class BlogDetailsComponent implements OnInit {
  debugger
  blog = [];
  post: Post;
  editing: boolean = false;
  posts: Observable<Post[]>


  constructor(private route: ActivatedRoute, private postservice: PostserviceService, private router: Router,) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnInit() {
    this.posts = this.postservice.getPosts();
    this.getPostById();
    // console.log(this.posts);

  }
  /* Function for display detail blog against id in the same page  */
  getPostById() {
    const id = this.route.snapshot.paramMap.get('id');
    this.postservice.getPostData(id).subscribe(data => {
      this.post = data
    })
  }
  /* Function for getting id and show detail blog against id in the same page  */
  Recall(id: string) {
    const id2 = id[1];
    this.postservice.getPostData(id2).subscribe(data =>
      this.post = data
    )
    console.log(this.post)
    this.router.navigate(['/blogDetail', id]);
  }
}
