import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ColorScssService } from '../shared/service/color-scss.service';

@Component({
  selector: 'app-whyvrentify',
  templateUrl: './whyvrentify.component.html',
  styleUrls: ['./whyvrentify.component.css']
})
export class WhyvrentifyComponent implements OnInit {

  constructor( private route:ActivatedRoute,private title:Title,private colorPicker:ColorScssService){} 
 
   ngOnInit(){
      this.colorPicker.setColorScheme('color-1');
     this.title.setTitle(this.route.snapshot.data['title']);
     function onMouseDown() {
      document.getElementById("myP").style.color = "red";
    }
    
    function mouseUp() {
      document.getElementById("myP").style.color = "green";
    }

    }
   
    services = [
      {
        img: "assets/images/app_landing1/icons/DoorstepServiceProvider.png",
        title:'Doorstep Service Provider',
        description: 'Customer’s convenience is our top priority, with that motto in heart we are delivering a doorstep service for E-Registration across many cities of Maharashtra.',
      },
      {
        img: "assets/images/app_landing1/icons/OneStopSolution.png",
        title:'One stop solution',
        description: 'We provide tenant police verification service as well. Thus, no find other providers or agents for related services. We are here to simplify renting ecosystem, thus one stop solution for all your renting needs. ',
      },
      {
        img: "assets/images/app_landing1/icons/StatusNotifications.png",
        title:'Status Notifications',
        description: " We keep you updated at each step of process. Our application helps you to track the status of your order, thus ensuring hassle-free experience for you. need to find other providers or agents for related services. We are heecosystem,re to simplify renting"
      },
      {
        img: "assets/images/app_landing1/icons/LiveEditing.png",
        title:'Live Editing Experience:',
        description: " Being technology enthusiast, we created platform for you wherein you can create and review your draft in real-time.You can also invite other party to collaborate for draft creation and review."
      },
      {
        img: "assets/images/app_landing1/icons/CustomClause.png",
        title:'Custom Clauses',
        description: " Every agreement is settled upon certain complex terms and conditions agreed between both owner and tenant. We are able to draft complex terms and conditions in much simplified legal jargons"
      },
      {
        img: "assets/images/app_landing1/icons/RenewalReminder.png",
        title:'Renewal Reminders',
        description: "Set your own reminder, our application will automatically send you the reminders for agreement renewal, thus, saves your last-minute hassles in future."
      },
      {
        img: "assets/images/app_landing1/icons/TransparentAffordablePricing.png",
        title:'Transparent & Affordable Pricing',
        description: " Enjoy our service with hassle free experience at highly affordable rate. We never charge any exorbitant  fees. We follow stringent transparent policies to define our charges.."
      },
      {
        img: "assets/images/app_landing1/icons/SeamlessandSkillfullExecution.png",
        title:'Seamless & Skillful Execution',
        description: "Being technology enthusiast, we created platform for you wherein you can create and review your draft in real-time.You can also invite other party to collaborate for draft creation and review."
      },
      {
        img: "assets/images/app_landing1/icons/MultiLangMultiChannelSupport.png",
        title:'Multi-Language, Multichannel Support',
        description: "Agreement language is English only. For related queries, we can answer you in English, Marathi, Hindi and Gujarati. We can also support you via multiple channels like E-mail, phone, Whatsapp and online chat."
      },
      {
        img: "assets/images/app_landing1/icons/PaperlessQueuelessCashless.png",
        title:'Paperless, Queueless, Cashless',
        description: "No paperwork is an objective of E-registration mechanism. We support India’s “Less-Cash Society” movement by offering multiple digital payment modes and other physical modes. And, no more queues, we are doorstep service provider."
      },
      {
        img: "assets/images/app_landing1/icons/MultiCityConvenience.png",
        title:'Multi-city Convinience',
        description: " No matter whether an owner or tenant residing in different cities, our multi-city availability can surely ensure hassle free experience for both. We are an only service provider who are providing multi- city convenience at affordable rate."
      },
      {
        img: "assets/images/app_landing1/icons/StateoftheartInfrastructure.png",
        title:'State-of-the-art Infrastructure',
        description: "E-registration has its complex technical requirement for identity validation process. We are well-equipped to deliver it in style."
      },
      
    ]
  
   
       
}





