<!--accordion2 section start-->
 
<section class="saas1 faq accordion testimonial-bg bg-light-inner" id="rentformat">
    <div class="container">
        <div class="row">
            <div class="agency col-12">
                <div class="title title2 title-inner">
                    <div class="main-title">
                        <h2 class="font-primary borders text-center main-text m-b-0"><span
                                class="text-uppercase">RentAgreement</span></h2>
                    </div>
  
                </div>
            </div>
            <div class="col-md-12">
                <div class="faq-block">
                    <div class="w-100">
                      <ngb-accordion [closeOthers]="true" class="accordion faq" activeIds="static-1">
                        <ngb-panel id="static-1" class="card">
                          <ng-template ngbPanelTitle class="card-header">
                              <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                              aria-expanded="false">
                             <a>
                                 <div class="fa fa-angle-right rotate"></div>
                             </a>
                             Which documents are required for online rent agreement registration (a.k.a.
                             leave and license Agreement)?
                         </div>
                          </ng-template>
                          <ng-template ngbPanelContent class="card-body">
                            Every party or entity (like Owner/s, Tenant/s and Witnesses) MUST have
                            UID- AADHAR and PAN card. PAN card is optional for Witnesses.
                          </ng-template>
                        </ngb-panel>
                        <ngb-panel id="static-2" class="card">
                            <ng-template ngbPanelTitle class="card-header">
                                <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                aria-expanded="false">
                               <a>
                                   <div class="fa fa-angle-right rotate"></div>
                               </a>
                               What is the process of online rent agreement registration?
                           </div>
                            </ng-template>
            
                            <ng-template ngbPanelContent class="card-body">
                                <ul>
                                    <li>Simply submit your information</li>
                                    <li> Verify and Approve your draft</li>
                                    <li>Schedule an appointment</li>
                                    <li>This process takes hardly few minutes of your valuable time. And, the
                                        agreement once submitted to department will be received within 2-4
                                        working days.</li>
                                    <li> <strong> Note:</strong> Across Maharashtra, Sub-Registrar offices
                                        remains closed on 2nd and 4th Saturdays of every month.</li>
                                    then rent agreement can surely be registered, irrespective of the purpose of
                                    renting.
                                    <!-- <a  [routerLink]="['faqs']" id="readme">>>READ  MORE</a> -->
                                </ul>
                            </ng-template>
                          </ngb-panel>
                          <ngb-panel id="static-3" class="card">
                              <ng-template ngbPanelTitle class="card-header">
                                  <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                  aria-expanded="false">
                                 <a>
                                     <div class="fa fa-angle-right rotate"></div>
                                 </a>
                                 Do I need to visit government office for online registration of rent
                                 agreement?
                             </div>
                              </ng-template>
              
                              <ng-template ngbPanelContent class="card-body">
                                <p>Big NO.Vrentify.com made it hassle free and super-simple for you, we visit to
                                    you at your convenient place and time, right at your doorstep. Being
                                    customer-centric, we did registrations at airports, railway
                                    stations,bus-stops, offices and etc locations where time is an ultimate
                                    constraint.

                                    <!-- <a [routerLink]="['/faqs']" id="readme">>>READ  MORE</a> -->
                                </p>
                              </ng-template>
                            </ngb-panel>
                            <ngb-panel id="static-4" class="card">
                                <ng-template ngbPanelTitle class="card-header">
                                    <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                    aria-expanded="false">
                                   <a>
                                       <div class="fa fa-angle-right rotate"></div>
                                   </a>
                                   Do I have to submit or upload hard copies of all documents on your web
                                            application?
                               </div>
                                </ng-template>
                
                                <ng-template ngbPanelContent class="card-body">
                                    No. Simply login to our web application and fill your renting details as
                                    accurately as possible. Rest will be handled by our team of experts. If you
                                    are not comfortable with Online form filling ask our support team for
                                    convenient assistance on 879-3333-921 or send an email
                                    registration@vrentify.com.
                                    <!-- <a [routerLink]="['/faqs']" id="readme">>>READ  MORE</a> -->
                                </ng-template>
                              </ngb-panel>
                              <ngb-panel id="static-5" class="card">
                                <ng-template ngbPanelTitle class="card-header">
                                    <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                    aria-expanded="false">
                                   <a>
                                       <div class="fa fa-angle-right rotate"></div>
                                   </a>
                                   Can I finish my rent agreement formalities on Weekends or Holidays?
                               </div>
                                </ng-template>
                
                                <ng-template ngbPanelContent class="card-body">
                                    Yes. You can. Team Vrentify remains available with their expertise on ALL
                                    days in between 10am-8pm.You may schedule your visit beyond regular hours
                                    for an extra
                                    <!-- <a [routerLink]="['/faqs']" id="readme">>>READ  MORE</a> -->
                                </ng-template>
                              </ngb-panel>
                              <ngb-panel id="static-6" class="card">
                                <ng-template ngbPanelTitle class="card-header">
                                    <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                    aria-expanded="false">
                                   <a>
                                       <div class="fa fa-angle-right rotate"></div>
                                   </a>
                                   Within how many days I will receive my registered agreement?
                               </div>
                                </ng-template>
                
                                <ng-template ngbPanelContent class="card-body">
                                    After submission, department reviews, verifies and register. Thus, ideally
                                    you will be receiving it within 2-4 business working days of government
                                    department,
                                    <!-- <a [routerLink]="['/faqs']" id="readme">>>READ  MORE</a> -->
                                </ng-template>
                              </ngb-panel>
                              <ngb-panel id="static-7" class="card">
                                <ng-template ngbPanelTitle class="card-header">
                                    <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                    aria-expanded="false">
                                   <a>
                                       <div class="fa fa-angle-right rotate"></div>
                                   </a>
                                   Can I register online rent agreement for commercial properties like shops,
                                            offices, godowns, sheds and lands?
                               </div>
                                </ng-template>
                
                                <ng-template ngbPanelContent class="card-body">
                                    Yes, of course. If your rented property is located in Maharashtra (selected
                                            cities) then rent agreement can surely be registered, irrespective of the
                                            purpose of renting.
                                    <!-- <a [routerLink]="['/faqs']" id="readme">>>READ  MORE</a> -->
                                </ng-template>
                              </ngb-panel>
                              <ngb-panel id="static-8" class="card">
                                <ng-template ngbPanelTitle class="card-header">
                                    <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                    aria-expanded="false">
                                   <a>
                                       <div class="fa fa-angle-right rotate"></div>
                                   </a>
                                   How many witnesses are required for online rent agreement registration?
                               </div>
                                </ng-template>
                
                                <ng-template ngbPanelContent class="card-body">
                                    In total only two witnesses are required. We recommend both witnesses should
                                    be provided by you and both parties should mutually understand who will be
                                    providing same. Almost anyone can execute as witness, person MUST have
                                    AADHAR card number with him/her.
                                    <!-- <a [routerLink]="['/faqs']" id="readme">>>READ  MORE</a> -->
                                </ng-template>
                              </ngb-panel>
                              <ngb-panel id="static-9" class="card">
                                <ng-template ngbPanelTitle class="card-header">
                                    <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                    aria-expanded="false">
                                   <a>
                                       <div class="fa fa-angle-right rotate"></div>
                                   </a>
                                   Do I need to pay stamp duty and registration fees to Vrentify.com?
                               </div>
                                </ng-template>
                
                                <ng-template ngbPanelContent class="card-body">
                                    For the submission of E-registration GRN number is required which you can
                                    generate by directly paying it to government of Maharashtra. Or, to
                                    facilitate further we can do it on your behalf at FREE OF COST, without any
                                    <!-- <a [routerLink]="['/faqs']" id="readme">>>READ  MORE</a> -->
                                </ng-template>
                              </ngb-panel>
                              <ngb-panel id="static-10" class="card">
                                <ng-template ngbPanelTitle class="card-header">
                                    <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                    aria-expanded="false">
                                   <a>
                                       <div class="fa fa-angle-right rotate"></div>
                                   </a>
                                   Can you provide sample draft for registered rent agreement for residential
                                   and commercial agreement?
                               </div>
                                </ng-template>
                
                                <ng-template ngbPanelContent class="card-body">
                                    Yes.It’s available for download here.Residential_ Rent Agreement_Draft,
                                    Commercial_Rent Agreement_Draft
                                    <!-- <a [routerLink]="['/faqs']" id="readme">>>READ  MORE</a> -->
                                </ng-template>
                              </ngb-panel>
                              <ngb-panel id="static-11" class="card">
                                <ng-template ngbPanelTitle class="card-header">
                                    <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                    aria-expanded="false">
                                   <a>
                                       <div class="fa fa-angle-right rotate"></div>
                                   </a>
                                   Can I add multiple owners as well as multiple tenants in my agreement?
                               </div>
                                </ng-template>
                
                                <ng-template ngbPanelContent class="card-body">
                                    Yes! In E-registration mechanism you can add multiple owners as well as
                                    tenants. Provided all must have UID AADHAR and PAN card.
                                    <!-- <a [routerLink]="['/faqs']" id="readme">>>READ  MORE</a> -->
                                </ng-template>
                              </ngb-panel>
                              <ngb-panel id="static-12" class="card">
                                <ng-template ngbPanelTitle class="card-header">
                                    <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                    aria-expanded="false">
                                   <a>
                                       <div class="fa fa-angle-right rotate"></div>
                                   </a>
                                   Can I add varying rent in my agreement?
                                   </div>
                                </ng-template>
                
                                <ng-template ngbPanelContent class="card-body">
                                    Yes! You can add varying rent for extended tenancy duration.
                                            <!-- <a [routerLink]="['/faqs']" id="readme">>>READ  MORE</a> -->
                                </ng-template>
                              </ngb-panel>
                              <ngb-panel id="static-13" class="card">
                                <ng-template ngbPanelTitle class="card-header">
                                    <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                    aria-expanded="false">
                                   <a>
                                       <div class="fa fa-angle-right rotate"></div>
                                   </a>
                                   Yes! You can add varying rent for extended tenancy duration.
                                   <!-- <a [routerLink]="['/faqs']" id="readme">>>READ  MORE</a> -->
                               </div>
                                </ng-template>
                
                                <ng-template ngbPanelContent class="card-body">
                                    Yes! You can add miscellaneous clauses not exceeding 700 characters as per
                                    your specific requirement. You can also index the count of your furniture
                                    <!-- <a [routerLink]="['/faqs']" id="readme">>>READ  MORE</a> -->
                                </ng-template>
                              </ngb-panel>
                              <ngb-panel id="static-14" class="card">
                                <ng-template ngbPanelTitle class="card-header">
                                    <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                    aria-expanded="false">
                                   <a>
                                       <div class="fa fa-angle-right rotate"></div>
                                   </a>
                                   Which government authority verifies and registers leave and license
                                            agreement?
                               </div>
                                </ng-template>
                
                                <ng-template ngbPanelContent class="card-body">
                                    Sub-registrar or Joint Sub registrar of Registration office where the
                                            agreement gets submitted verifies and registers your agreement.
                                            <!-- <a [routerLink]="['/faqs']" id="readme">>>READ  MORE</a> -->
                                </ng-template>
                              </ngb-panel>
                              <ngb-panel id="static-15" class="card">
                                <ng-template ngbPanelTitle class="card-header">
                                    <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                    aria-expanded="false">
                                   <a>
                                       <div class="fa fa-angle-right rotate"></div>
                                   </a>
                                   Can I upload my agreement to the government agency to get it registered?

                               </div>
                                </ng-template>
                
                                <ng-template ngbPanelContent class="card-body">
                                    No. There is no upload facility in current version of E-registration
                                    mechanism. You can send us details and Vrentify can execute it further with
                                    your permission.
                                    <!-- <a [routerLink]="['/faqs']" id="readme">>>READ  MORE</a> -->
                                </ng-template>
                              </ngb-panel>
                              <ngb-panel id="static-16" class="card">
                                <ng-template ngbPanelTitle class="card-header">
                                    <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                    aria-expanded="false">
                                   <a>
                                       <div class="fa fa-angle-right rotate"></div>
                                   </a>
                                   What if my owner is residing outside the country or city? <!-- Can I still opt -->
                                   for E-registration of my leave and license agreement?
                               </div>
                                </ng-template>
                
                                <ng-template ngbPanelContent class="card-body">
                                    Yes! You can, if owner has assigned Power of Attorney to someone. In that
                                    case POA can act as owner and his/her identity is considered valid.
                                    <!-- <a [routerLink]="['/faqs']" id="readme">>>READ  MORE</a> -->
                                </ng-template>
                              </ngb-panel>
                              <ngb-panel id="static-17" class="card">
                                <ng-template ngbPanelTitle class="card-header">
                                    <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                    aria-expanded="false">
                                   <a>
                                       <div class="fa fa-angle-right rotate"></div>
                                   </a>
                                   Can I complete my registration formalities if I am located in another city
                                   of India?
                               </div>
                                </ng-template>
                
                                <ng-template ngbPanelContent class="card-body">
                                    Yes. Absolutely. No matter where you are, you don’t need to travel all the
                                    way to Maharashtra, we will be assisting you anywhere in India. Simply call
                                    879-3333-921 or mail at registration@vrentify.com , we would love to help
                                    you.
                                    <!-- <a [routerLink]="['/faqs']" id="readme">>>READ  MORE</a> -->
                                </ng-template>
                              </ngb-panel>
                              <ngb-panel id="static-18" class="card">
                                <ng-template ngbPanelTitle class="card-header">
                                    <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                    aria-expanded="false">
                                   <a>
                                       <div class="fa fa-angle-right rotate"></div>
                                   </a>
                                   Is an e-registered agreement as valid as traditional offline agreement?
                               </div>
                                </ng-template>
                
                                <ng-template ngbPanelContent class="card-body">
                                    Yes. It is absolutely valid and legitimate for all your purposes. It is
                                    governed by the government of Maharashtra.
                                    <!-- <a [routerLink]="['/faqs']" id="readme">>>READ  MORE</a> -->
                                </ng-template>
                              </ngb-panel>
                              <ngb-panel id="static-19" class="card">
                                <ng-template ngbPanelTitle class="card-header">
                                    <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                    aria-expanded="false">
                                   <a>
                                       <div class="fa fa-angle-right rotate"></div>
                                   </a>
                                   How shall I get my registered copy? Whether as a soft copy or hard copy?
                               </div>
                                </ng-template>
                
                                <ng-template ngbPanelContent class="card-body">
                                    You can get it in both formats. We send soft copy via mail as default.
                                    On-request we send hard-copies too, but it will take certain days based upon
                                    your location.
                                    <!-- <a [routerLink]="['/faqs']" id="readme">>>READ  MORE</a> -->
                                </ng-template>
                              </ngb-panel>
                      </ngb-accordion>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </section>
  
  <!--accordion2 section end-->
