import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { ConfigService } from './config.service';
import { BaseService } from "./base.service";
import { Observable, BehaviorSubject } from 'rxjs/Rx';
import './rxjs-operators';


@Injectable({
  providedIn: 'root'
})
export class LoginServiceService extends BaseService {



  baseUrl: string = '';

  // Observable navItem source
  private _authNavStatusSource = new BehaviorSubject<boolean>(false);
  // Observable navItem stream
  authNavStatus$ = this._authNavStatusSource.asObservable();

  private loggedIn = false;

  constructor(private http: Http, private configService: ConfigService) {
    super();
    this.loggedIn = !!localStorage.getItem('auth_token');
    // ?? not sure if this the best way to broadcast the status but seems to resolve issue on page refresh where auth status is lost in
    // header component resulting in authed user nav links disappearing despite the fact user is still logged in
    this._authNavStatusSource.next(this.loggedIn);
    this.baseUrl = configService.getApiURI();

  }

  login(UserName, Password, loginType) {
    debugger;
    let headers = new Headers();
    let Pid: any;
    let OTP: number;

    if (loginType == 1) {
      Pid = '6F8FC206-59FB-4919-9117-FD168EE86090';

    }
    else {
      Pid = '88ED9993-329F-4D20-AF53-391666F1FE6A';

    }
    headers.append('Content-Type', 'application/json');

    return this.http
      .post(
        this.baseUrl + '/login',
        JSON.stringify({ UserName, Password, Pid }), { headers }
      )
      .map(res => res.json())
      .map(res => {
       // alert(JSON.stringify(res));
        localStorage.setItem('auth_token', res.token);
        //New Storage will be needed for Role based value to save in storage .. this will be use in login function to redirect propery on basis of this
        //Added New Line on 2018-12-32 By Nilesh to set username in session
       var FkRoleMaster =JSON.parse(res.result);
        localStorage.setItem('FkRoleMaster',FkRoleMaster[0].FkRoleMaster);
        console.log(JSON.stringify(res));
        localStorage.setItem('UserName', UserName);
        this.loggedIn = true;
        this._authNavStatusSource.next(true);
        return true;
      })
      .catch(
        this.handleError
      );
  }

  logout() {
    localStorage.removeItem('auth_token');
    localStorage.removeItem('UserName');
    localStorage.removeItem('FkRoleMaster');
    this.loggedIn = false;
    this._authNavStatusSource.next(false);
  }

  isLoggedIn() {
    return this.loggedIn;
  }
}

