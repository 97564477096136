
<!-- subscribe section end-->
<section class="p-b-0 " id="agreementformat">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="title title2 title-inner">
                    <div class="main-title">
                        <h2 class="font-primary borders text-center "><span>Rent Agreement Format</span></h2>
                    </div>
                    <h5>Get FREE Sample Rent Agreement Format In Your Inbox </h5>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="app1 subscribe bg ">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 reqformt">
                 <h4 class="text-white">Are you wondering how your agreement draft would look like or how your
                        e-registered agreement would be like????</h4>
                 <h4 class="text-white text-center" >Simply Relax. Below buttons are your answers. Click it!</h4><br>
            </div>
        </div>
        <div class="row">
            <div class="col-md-8 offset-md-2">
                <div class="subscribe">
                    <div class="center-content inpt">
                        <div class="form-group ">
                            <div class="flex">
                                <input type="email" #email id="email" class="form-control" placeholder="Please Enter Your Email Address"
                                       name="email" >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div><br>
        <div class="row">
            <div class="col-md-8 offset-md-2">
                <div class="center-content"> 
                    <div class="text">
                 <button type="submit" class="form-control"  class=" btn btn-default b " formControlName="Email" value="" name="sampleagree"(click)="
                 Email_send()">Email Me Sample Draft</button>
                &nbsp;
                &nbsp;
                <button type="submit" class="form-control "  class=" btn btn-default bb" value="" name="sampleagree" (click)="
                Email_send()" >Email Me Sample E-registered Agreement</button>
             
            </div>
            </div>
            </div>
        </div>
        </div>
</section>

