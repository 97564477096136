<!-- feature section-->
<section id="feature" class="saas1 service" id="process">
    <div class="about-chat">
        <div class="container">
            <div class="row">
                <div class=" col-md-12 text-center">
                    <div class="title">
                        <!-- <img src="assets/images/saas1/title-logo.png" alt="title-logo" class="img-fluid"> -->
                        <div class="main-title">
                            <h2 class="font-primary borders text-center">
                              Super-Simple Process
                            </h2><br>
                             <h6>As Simple As 1.2.3.</h6>
                        </div>
                        <hr style=" margin-left: 31rem; background-color:#007bff ;">
                        <div class="sub-title">
                            <p class="p-padding">At each step , we ensure hassle-free experience for you. Our end-to-end support, right from drafting to delivery will surely enchant you. Believe us , no need to move out of your chair or cushions, simply relax, and enjoy your sip of coffee. It's a doorstep service, right?</p>
                        </div>
                    </div>
                      
                </div>
                <div class="col-lg-4 col-md-6 service-container" *ngFor="let data of features">
                    <div class="team-img">
                        <img [src]="data.img" alt="stay-connected" class="img-fluid"  >
                        <div class="feature-content text-center ">
                            <h4 class="service-heading "> {{data.title}} 
                              <!--   <span>class="theme-color">{{data.subTitle}}</span> -->
                            </h4>
                            <hr style=" margin-left: 8rem; background-color:#007bff ;">
                            <p>{{data.description}}</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>
<!-- end feature section -->

