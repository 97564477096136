<section id="calculator">
<div class="row">
  <div class="col-md-8 offset-md-2">
    <div class="title title2">
      <h2>It’s Super-Simple, Right?</h2>
      <h4 style="color: black;"> let's calculate government charges for you.</h4>
      <div class="circle">
      </div>
    </div>
</div>
 </div>
<form #f="ngForm" [formGroup]="Form">
  <div class="form-group">
    <div style="border: 3px solid #00abc9; padding:3%;" class="col-sm-12">
      <div class="row bg">
        <div class="col-sm-12">
          <h1 class="cal1" style="text-align:center; font-size:230%; font-family:Poppins,sans-serif;"><span class="text-white">Calculator</span></h1>
        </div>
      </div> <br>
<div class="container ">
  <div class="row">
    <div class="col-md-3 margin">
      <div class="form-group w">
        <div class="col">
          <h6>Proper Rented At:</h6>
      </div><br>
      <select class="form-control" id="location" formControlName="location" name="location" placeholder="In rupees" [(ngModel)]="citySelected_default"
 [ngClass]="{'form-control':true, 'is-invalid':Form.controls.location.invalid && Form.controls.location.touched}">
  <option selected value="0">Select Your City</option>
  <option *ngFor="let name of cities" [value]="name.id" >
    {{name.cityName }} </option>
</select>
<small *ngIf="Form.controls.location.errors?.required && Form.controls.location.touched"style="color:red;">
  This field is required.</small>
</div>
</div>
          <div class="col-md-2 wid">
             <h6 class="wi">Property Rented At:</h6><br>
             <div class="form-check wid">
              <input class="form-check-inline  "  style="width: 11%;" type="radio" name="radRentType1" id="Urban" value="Urban" [checked]="true" (click)="handleChange1($event)">
              <label class="form-check-inline label lbl" for="gridRadios1"> Urban </label>
              <input class="form-check-inline"  style="width: 11%;" type="radio" name="radRentType1" id="Rural" value="Rural" (click)="handleChange2($event)">
              <label class="form-check-inline label" for="gridRadios2"> Rural</label>
           </div>
      </div>
      <div class="col-md-4 form-group ">
        <h6>Renting purpose:</h6><br>
        <div class="form-check mar">
          <input class="form-check-inline " style="width: 5%;" type="radio" name="radRentType2" id="Residential" value="Residential" checked>
          <label class="form-check-inline label " for="gridRadios1">  Residential </label>
          <input class="form-check-inline  inpt" type="radio" style="width: 5%;" name="radRentType2" id="Commercial" value="Commercial" >
          <label class="form-check-inline label" for="gridRadios2">Commercial </label>
        </div>
      </div>
      <div class="col-md-3 form-group" id="staticParent3">
        <div class="form-group widt">
          <h6>Property Rented for Months:</h6><br>
          <input class="form-control" formControlName="txtForPeriods" allow-decimal="false" allow-negative="false"  
          type="number" (keypress)="restrictMinus($event)" (keyup)="monthsvalidation($event)" id="txtForPeriods"
          placeholder="Allowed upto 60 Months" min="1"   [ngClass]="{'form-control':true, 'is-invalid':Form.controls.txtForPeriods.invalid && Form.controls.txtForPeriods.touched}">
          <small *ngIf="Form.controls.txtForPeriods.errors?.required && Form.controls.txtForPeriods.touched"
          style="color:red;">This field is required.</small>
       </div>
      </div>
  <div class="container ">
  <div class="row">
    <div class="col-md-3 margin">
      <div class="form-group">
          <h6>Refandable deposite:</h6><br>
          <input class="form-control" formControlName="txtRefundableSecurityDeposites" aria-describedby="emailHelp"
          style="text-align:center" type="number" (keypress)="restrictMinus($event)" min=0 ng-value="myVar"
          id="txtRefundableSecurityDeposites" placeholder="In rupees"> 
      </div>
    </div>
      <div class="col-md-3 ">
          <h6>No Refandable deposite:</h6><br>
          <input class=" form-control" formControlName="NonRefund" style="text-align:center" type="number"
              id="NonRefund" min=0 (keypress)="restrictMinus($event)" placeholder="In rupees" >
            <!--    <show-errors [control]="Form.controls.NonRefund"></show-errors> -->
      </div>
      <div class="col-md-3 ">
        <div class="form-group">
          <h6>Rent Type:</h6><br>
          <div class="form-check mar">
            <input class="form-check-inline " style="width: 5%;"  type="radio" name="radRentType" id="Fixed" value="FixedRent" (click)="toggle($event)" checked>
            <label class="form-check-inline label  " for="gridRadios1">  Fixed </label>
            <input class="form-check-inline" style="width: 5%;"  type="radio" name="radRentType" id="Variable" value="VaryingRent" (click)="increment($event)"
             (click)="toggle($event);rentForMonths($event)" (click)="SetSixtyToMonth3($event)" (click)=SetSixtyToMonth4($event)  required="">
            <label class="form-check-inline label" for="gridRadios2"> Variable </label>
          </div>
        </div>
        </div>
      <div class="col-md-3 ">
        <div class="form-group" *ngIf="show1">
          <label id="Rentamt"> <b>Rent Amount</b> <span style="color: red;">&nbsp;*</span> </label><br>
          <input class=" form-control" (keypress)="restrictMinus($event)" formControlName="txtMonthlyRent"
              type="number" min=0 id="txtMonthlyRent" placeholder="In rupees" >
          <!--  <show-errors [control]="Form.controls.txtMonthlyRent"></show-errors> -->
        </div>
        </div>
   </div>
  </div>
</div>
<div class="form-group" FormGroupName="Mode">
  <div class="container" *ngIf="show" id="Incremental">
    <div class="term_plan_calculation">
     <div class="row form-group box" id="termrow1">
      <!-- 1.first -->
         <div class="col-md-3"><label class="lb">Term1(from month)</label><br> 
          <input class=" form-control" formControlName="FromMonth1" style="text-align:center;" type="number" min=0
          value="1" [readonly]="true" [(ngModel)]="this.term1" (keypress)="restrictMinus($event)" readonly=""
          id="FromMonth1" required="">
          </div>
      <!--1. second -->
          <div class="col-md-3"><label class="lb"> Term1(to month)</label><br>
            <input class=" form-control" formControlName="ToMonth1" style="text-align:center;" type="number"
            [(ngModel)]="this.term1Month" min=0 id="ToMonth1" (keypress)="restrictMinus($event)"
            (keyup)="onKey($event)" (click)="increament2($event)" required="">
            <!--  <show-errors [control]="Form.controls.ToMonth1"></show-errors> -->
          </div>
      <!-- 1.Third -->
          <div class="col-md-3"><label class="lb">Term monthly rental</label><br> 
            <input class=" form-control" formControlName="Month1Rental" style="text-align:center;" type="number" min=0
            id="Month1Rental" (keypress)="restrictMinus($event)" [(ngModel)]="this.rent"
            (focusout)="rentForMonths($event)" required="">
        <!--  <show-errors [control]="Form.controls.Month1Rental"></show-errors> -->
          </div>
      <!-- 1.four -->
          <!-- <div class="col-md-3">percent %<br>
              <input class="form-control" type="text" name="name" required>
          </div> -->
      </div>
     <!-- !--new--> 
      <!-- 2. first -->
      <div class="row form-group box" id="termrow2">
          <div class="col-md-3"> <label class="lb">Term2(from month)</label><br> 
            <input class=" form-control" formControlName="FromMonth2" [(ngModel)]="this.sum"
            style="text-align:center;" type="number" readonly="" min=0 id="FromMonth2"
            (keypress)="restrictMinus($event)" (keyup)="onKey($event)" required="">
            <!--    <show-errors [control]="Form.controls.FromMonth2"></show-errors> -->
          </div>
       <!-- 2.second    -->
          <div class="col-md-3"><label class="lb">Term2(to month)</label><br>
            <input class=" form-control" formControlName="ToMonth2" [(ngModel)]="this.term2toMonth"
            style="text-align:center;" type="number" min=0 (keypress)="restrictMinus($event)" id="ToMonth2"
            (keyup)="onKey($event)" (keyup)="restrictupdate2($event)" (click)="increament3($event)" required="">
        <!--   <show-errors [control]="Form.controls.ToMonth2"></show-errors> -->
          </div>
       <!-- 2.Third -->
          <div class="col-md-3"><label class="lb lbm">Term2 monthly rental</label><br>
            <input class=" form-control" formControlName="Month1Rental2" min=0 
            (focusout)="ChangeVal()" (focusout)="ChangeMonths()" (keypress)="restrictMinus($event)" style="text-align:center;"
              type="number" id="Month1Rental2" required="">
              <!--  <show-errors [control]="Form.controls.Month1Rental2"></show-errors> -->
          </div>
       <!-- 2.four -->
          <div class="col-md-3">percent %<br>
            <input class=" form-control" formControlName="Percent2" type="number" id="Percent2" step="0.01"
            required="" (focusout)="changePercent()" [(ngModel)]="this.percentchange"
            style="text-align:center;border-bottom-color:#00abc9;">
        <!--  <show-errors [control]="Form.controls.Percent2"></show-errors> -->
          </div>
      </div>
<!-- 3.first -->
    <div class="form-group" *ngIf="show2">
      <div class="row form-group box" id="termrow3">
          <div class="col-md-3"><label class="lb">Term3(from month)</label><br> 
              <input class=" form-control" (keypress)="restrictMinus($event)" formControlName="FromMonth3"
              [(ngModel)]="this.sum1" min=0 style="text-align:center;" type="number" readonly="" id="FromMonth3"
              (keyup)="onKey($event)" required="">
            <!--    <show-errors [control]="Form.controls.FromMonth3"></show-errors> -->
          </div>
    <!-- 3. second      -->
          <div class="col-md-3"><label class="lb">Term3(to month)</label><br>
              <input class=" form-control" (keypress)="restrictMinus($event)" formControlName="ToMonth3"
              [(ngModel)]="this.term3toMonth" min=0 (keyup)="onKey($event)" (keyup)="restrictupdate3($event)"
              (click)="increament4($event)" style="text-align:center;" type="number" id="ToMonth3" required="">
            <!--  <show-errors [control]="Form.controls.ToMonth3"></show-errors> -->
          </div>
   <!-- 3.Third     -->
          <div class="col-md-3 "> <label class="lb ">Term3 monthly rental</label><br>
              <input class=" form-control" formControlName="Month1Rental3" (keypress)="restrictMinus($event)"
              type="number" id="Month1Rental3" min=0 (focusout)="ChangeVal1()" (focusout)="ChangeMonths1()" style="text-align:center"
              [(ngModel)]="this.percent1" required="">
            <!--   <show-errors [control]="Form.controls.Month1Rental3"></show-errors> -->
          </div>
  <!-- 3.four     -->
          <div class="col-md-3"><label>percent %</label><br>
            <input class=" form-control" formControlName="Percent3" type="number" id="Percent3" step="0.01"
            [(ngModel)]="this.percentchange1" (focusout)="changePercent1()" required=""
              style="text-align:center;border-bottom-color:#00abc9;">
            <!--  <show-errors [control]="Form.controls.Percent2"></show-errors> -->                
          </div>
        </div>
</div>
  <!-- 4.first -->
  <div class="form-group" *ngIf="show3">
      <div class="row form-group box" id="termrow4">
          <div class="col-md-3">Term4(from month)<br>
              <input class=" form-control" formControlName="FromMonth4" style="text-align:center;" type="number"
              readonly="" min=0 (keypress)="restrictMinus($event)" id="FromMonth4" [(ngModel)]="this.sum2"
              (keyup)="onKey($event)" required="">
            <!-- <show-errors [control]="Form.controls.FromMonth4"></show-errors> -->               
         </div>
      <!-- 4.second    -->
          <div class="col-md-3">Term4(to month)<br>
              <input class=" form-control" formControlName="ToMonth4" style="text-align:center;" type="number"
              id="ToMonth4" min=0 (keypress)="restrictMinus($event)" [(ngModel)]="this.term4toMonth"
              (keyup)="restrictupdate4($event)" (keyup)="onKey($event)" required="">
            <!--   <show-errors [control]="Form.controls.ToMonth4"></show-errors> -->                   
          </div>

     <!-- 4.Third -->
       <div class="col-md-3">Term4 monthly rental<br>
          <input class=" form-control" formControlName="Month1Rental4" type="number" id="Month1Rental4"
          style="text-align:center" (keypress)="restrictMinus($event)" min=0 [(ngModel)]="this.percent2"
          (focusout)="ChangeMonths2()" (focusout)="ChangeVal2()" required="">
        <!--    <show-errors [control]="Form.controls.Month1Rental4"></show-errors> -->
      
      </div>

  <!-- 4.four     -->
          <div class="col-md-3"> percent %<br> 
              <input class=" form-control" formControlName="Percent4" type="Percent4" id="Percent4" step="0.01"
              (focusout)="changePercent2()" style="text-align:center;border-bottom-color:#00abc9;"
              [(ngModel)]="this.percentchange2">
          </div>
  </div>    
  </div>
<!-- 5.first -->
<div class="form-group" *ngIf="show4">
      <div class="row form-group box" id="termrow5">
          <div class="col-md-3">Term5(from month)<br>
            
            <input class=" form-control" formControlName="FromMonth5" style="text-align:center;" type="number"
            readonly="" id="FromMonth5" min=0 required="" (keypress)="restrictMinus($event)" [(ngModel)]="this.sum3"
            (keyup)="onKey($event)">
              <!--  <show-errors [control]="Form.controls.FromMonth5"></show-errors> -->
          </div>
      <!-- 5.second -->
          <div class="col-md-3">Term5(to month)<br> 
            <input class=" form-control" formControlName="ToMonth5" style="text-align:center;" type="number"
            id="ToMonth5" min=0 (keypress)="restrictMinus($event)" required="" (keyup)="restrictupdate5($event)"
            [(ngModel)]="this.term5toMonth" (keypress)="onKey($event)">
            <!--  <show-errors [control]="Form.controls.ToMonth5"></show-errors> -->
          </div>
      <!-- 5.Third -->
          <div class="col-md-3">Term5 monthly rental<br>
             
            <input class=" form-control" formControlName="Month1Rental5" style="text-align:center;" type="number"
            (keypress)="restrictMinus($event)" min=0 (focusout)="Percentchange($event)" [(ngModel)]="this.percent3"
            id="Month1Rental5">
              <!--   <show-errors [control]="Form.controls.Month1Rental5"></show-errors> -->
          </div>
      <!-- 6.four -->
          <div class="col-md-3"> percent %<br> 
            <input class=" form-control" formControlName="Percent5" type="number" id="Percent5"
            [(ngModel)]="this.percentchange3" (keyup)="calculatePercent4($event)"
            style="text-align:center;border-bottom-color:#00abc9;">
            <!--     <show-errors [control]="Form.controls.Percent5"></show-errors> -->
          </div>
      </div>
      </div>
  </div>
</div>
  </div>
  <div class="pes-info">
      <div class="row ">
          <div class="col-md-6 margin" style="padding-left: 0px;" id="staticParent4">
            <div class="form-group">
             <b> Mobile No:</b><span style="color: red;">&nbsp;*</span><br> 
             <input class="form-control" formControlName="UserMobile" (keypress)="PhoneValidate($event)" minlength=10 maxlength=10 
              type="number" id="UserMobile" placeholder=" We’ll SMS your charges." [ngClass]="{'form-control':true, 'is-invalid':Form.controls.UserMobile.invalid && Form.controls.UserMobile.touched}">
              <small *ngIf="Form.controls.UserMobile.errors?.required && Form.controls.UserMobile.touched"
                style="color:red;">This field is required.</small>
              <small *ngIf="Form.controls.UserMobile.errors?.pattern && Form.controls.UserMobile.touched"
                style="color:red;">Mobile Number must be valid</small>
            <!-- <show-errors [control]="Form.controls.UserMobile"></show-errors> -->
          </div>
          </div>
          <div class="col-md-6 mr">
             <b> Email id: </b><span style="color: red;">&nbsp;* </span><br>
             <input class="form-control" formControlName="emails" name="emails" type="email" id="emails"
             placeholder="And, we’ll Email your charges too."  [ngClass]="{'form-control':true, 'is-invalid':Form.controls.emails.invalid && Form.controls.emails.touched}">
             <small *ngIf="Form.controls.emails.errors?.required && Form.controls.emails.touched"
               style="color:red;">This field is required.</small>
             <small *ngIf="Form.controls.emails.errors?.pattern && Form.controls.emails.touched"
               style="color:red;">Email must be valid</small>
            <!--  <show-errors [control]="Form.controls.emails"></show-errors> -->           
         </div>
      </div>
  </div>
  <div class="cal">
      <div class="row">
           <div class="col-md-3 col-sm-3 md">
             <!-- <button type="button" class="btn btn-primary btn-lg">Large button</button> -->
                <input type="submit" (click)="calculateResult()" class="btn btn-primary" style="background-color: #FFA500;  width:110%;"
                 value="Calculate Government Fees"    [disabled]="disableButton"> 
           </div>
          <div class="col-md-3 col-sm-3 cd">
              <input type="button" class="btn btn-primary"  (click)="reset($event)" style="background-color: #FFA500; width:110%;" value="Reset"
                id="btnReset">
         </div>
      </div>
  </div>
  <div id="Result" *ngIf="show5">
  <div class="row empty-box stamp"> 
    <div class="col-md-3">  
      <label style="border: 3px solid #00abc9; text-align:center; width:110%;" class="myclass">Stamp Duty:Rs.
        <span id="Stamp" [innerText]="this.stampDuty"></span> </label>
    </div>
      <div class="col-md-3">  
        <label style="border: 3px solid #00abc9;text-align:center; width:110%;">Registration Fee:Rs.
          <span id="newrate" [innerText]="this.radioUrbRular"> </span>* </label>
      </div>
  </div>
<div class="container">
      <div class="row">
          <div class="col-md-3 b">
              <input type="button"
                 style="color:white; background-color:#00abc9;width:117%;;" class="btn"
                 value="Send This Quotation On Your Email" id="Button1" (click)="SendCalculations()">
          </div>
      </div>
    </div>
</div>
</div>
</div>
</div>
</form>
<br>
<br>
</section>