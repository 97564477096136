import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MainService } from '../Service/main.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  ApiResult: any;
  emailId: string|any;
  submitted = false;
  sendemailid:string|any;
  form!:FormGroup;

  constructor( private modalService: NgbModal,private fb:FormBuilder,private mainService:MainService,private router:Router) { }
  ngOnInit() {
    this.form=this.fb.group({
      Email:['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      Name:['',Validators.required],
      phone:['',[Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      Message:['',Validators.required],
  });
this.SystemSendEmail_SelectEmail();
}

openVerticallyCentered(content) {
    this.modalService.open(content, { centered: true, size: 'lg' });
  }

SystemSendEmail(){ 
  var Moobj: any = {};
    Moobj.Pid ='2349d0f0-9f5d-4d3a-849d-4c9fcce92456';
    this.mainService.TwoMethod(Moobj).then(value => {
      this.ApiResult = value;
      this.ApiResult = this.ApiResult.table;
     this.emailId=this.ApiResult[0].emailId
    });
  }
  SystemSendEmail_SelectEmail() {debugger
    var Moobj: any = {};
    Moobj.Pid = '6ab0afa8-bc00-45c6-90cd-959b499d7617';
    this.mainService.TwoMethod(Moobj).then(value => {
      this.ApiResult = value;
      this.ApiResult = this.ApiResult.table;
      this.sendemailid = this.ApiResult[0].emailId
      console.log(JSON.stringify(this.sendemailid))
      localStorage.setItem("GetSendMail",this.sendemailid)
    });
  }
  get f(){
    return this.form.controls;
  }
Email_send() {
  this.SystemSendEmail_SelectEmail();
    this.submitted = true;
    // stop here if form is invalid
    if (this.form.invalid) {
        return;
    }
  var name = this.form.get("Name").value;
  var message = this.form.get("Message").value;
  var MobileNo = this.form.get("phone").value;
  var Email = this.form.get("Email").value;
  var EmailBodyMsg = "<table ><thead><tr><th>Vrentify-FAQ</th></tr></thead><tbody><tr><td>Name:</td><td>" + name + "</td></tr><tr><td>Phone-No:</td><td>" + MobileNo + "</td></tr><tr><td>Message:</td><td>" + message + "</td></tr><tr><td>Email:</td><td>" + Email+"</td></tr></tbody></table>";
 var Moobj: any = {};
 Moobj.EmailSubject="For Contact Us";
 this.sendemailid= localStorage.getItem("GetSendMail");
 Moobj.EmailTo =this.sendemailid;
 Moobj.PkSystemEmailSetting=4; 
 Moobj.message=EmailBodyMsg;
 this.mainService.emailMethod(Moobj).subscribe(value => {
   this.ApiResult = value;
   });
   alert(" Submit Successfull");
   this.Reset();
 }
 
Reset(){
    this.submitted = false;
    this.form.reset()
 }
}
