import { Injectable } from '@angular/core';
import { BaseService } from '../login/base.service'
import { ConfigService } from '../login/config.service'
import { Http, Response, Headers, RequestOptions, RequestMethod } from '@angular/http';
const  url="https://hrtft.com/api/ZION/SENDMAIL";

@Injectable({
  providedIn: 'root'
})
export class MainServiceService extends BaseService{
  baseUrl: string = '';

  constructor(private http: Http, private configService: ConfigService) {
    super();
    this.baseUrl = configService.getApiURI();
  }

   
  objInnerMethod(MoObj:any) {
    var obj = MoObj;
    var body = JSON.stringify(obj);
    //console.log(JSON.stringify(body));
    //alert(JSON.stringify(body) + 'inside');
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    let authToken = localStorage.getItem('auth_token');
    headers.append('Authorization', `Bearer ${authToken}`);
    let options = new RequestOptions({ headers: headers });
     
    return this.http.post(this.baseUrl + "/CodexH", body, options)
      .map((data: Response) => {
      //  alert(JSON.stringify(data));
        return data.json() ;
      })
  }


  objOuterMethod(MoObj:any) {
    var obj = MoObj;
    var body = JSON.stringify(obj);
   //alert(JSON.stringify(body))
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.baseUrl + "/core", body, options)
      .map((data: Response) => {
        return data.json() ;
      })
  }
  emailMethod(MoObj:any) {
    MoObj.ApplicationId = "1011";
      MoObj.IsCipher = "0";
    var obj = MoObj;
    var body = JSON.stringify(obj);
  // alert(JSON.stringify(body))
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return this.http.post(url, body, options)
      .map((data: Response) => {
        return data.json() ;
        })
     }
     
  //to get checkbox value in array
  public selEmpList = []
  
  setList(list){
    this.selEmpList = list;  
    
  }
  
  getList(){
    return this.selEmpList;
  }
}
