import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Menu
export interface Menu {
	path?: string;
	title?: string;
   type?: string;
   icon?:string;
	badgeType?: string;
	badgeValue?: string;
   active?: boolean;
   megaMenu?: boolean;
   megaMenuType?: string; // small, medium, large
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
  providedIn: 'root'
})
export class NavService {

  constructor() {   }

  MENUITEMS: Menu[] = [
	 {

         title: 'Home', type:'link', path:"/home"
    } ,     
    {

      title: 'Process', type:'link', path:'/process'
    } ,        
	 {

      title: 'Calculator', type:'link', path:'/calculator'
   } ,		
   {

      title: 'WhyVrentify', type: 'link', path:'/services'
   } ,
   {

      title: 'HappyClients', type: 'link', path:'/clients'
   } ,
   {

      title: 'Blogs', type: 'link', path:'/blogs'
  }  ,
  {

   title: 'Faq', type: 'link', path:'/faq'
  },
  {

   title: 'Login', type: 'link', path:'/loginf'
  } 
  ]
  	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
   
}
