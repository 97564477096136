<!-- <app-home></app-home> -->
<!-- feature section-->
<section id="feature" class="saas1 service" id="process" style="margin-top:6rem;">
    <div class="about-chat">
        <div class="container" >
            <div class="row">
                <div class=" col-md-12 text-center">
                    <div class="title">
                        <!-- <img src="assets/images/saas1/title-logo.png" alt="title-logo" class="img-fluid"> -->
                        <div class="main-title">
                            <h2 class="font-primary borders text-center color">
                                Terms and Conditions
                            </h2><br>
                             
                        </div>
                        <hr class="bg_color">
                        <div style="text-align: justify;">
                            <p>
                               <b>1)	Scope of Service:</b> 
                                The scope of our service is restricted to helping you register your leave and license agreement. We do not take any responsibility for the incorrect/incomplete information, clauses mentioned in the agreement and consequent disputes arising out of registered agreement.
                                <br>
                                <br>
                               <b> 2)	Revision Facility: </b>
                                It is recommended that client should provide all the information in clear and accurate manner; also, client should provide complete information together and not in pieces via single communication channel that will enable Vrentify to create a draft that is complete and accurate in each aspect. Vrentify allows their client to have two revisions completely free.Vrentify reserve the right to request advance payment for any modifications which is requested after the draft is edited twice.It is expected that ideally, client should provide accurate info on our portal and if applying for other channels then its responsibility of a client to provide correct and accurate.
                                 <br>
                                 <br>
                              <b>  3)	Responsibility and Non-Responsibility: </b>
                              <br>
                                We are not responsible for:<br>
                                Any losses/cancellations/delays caused by the registration offices such as:<br>
                                i)	The government registration system, it’s linkage with AADHAR being non-functional/inaccessible at the time of the scheduled appointment.<br>
                                ii)	Unexplained and unforeseen delays by the registration office in registering the submitted agreement.<br>
                                iii)	Any Disputed Property
                                <br><br>
                                We are responsible for:
                                
                                <br>
                                i)	Security of your personal data<br>
                                ii)	Follow up with government officials till completion of the registration process <br>
                                iii)	Provide status on frequent basis of the progress in Vrentify’s process <br>
                                iv)	Providing customer support <br>
                                v)	Helpful points about leave and license agreement<br>
                                vi)	Reducing the complexity in time-consuming government process of housing rental registration.<br><br>
                                2)	As per the law, stamp duty needs to be paid either in advance. In case of E-Registration, delay in payment of stamp duty lead to the registrar raising an objection, and the entire activity having to be repeated. Vrentify shall not be responsible for these issues if payment is not received from the customer within the stipulated time, as requested by Vrentify.<br>
                                3)	For registrations, executed through power of attorney / board resolution / authority letter, Vrentify will advise you on the necessary requirements. Vrentify does not take responsibility for successful registration if the power of attorney/ Board Resolution / Authority Letter is improperly drafted and/or executed, and objections are raised by the Sub-Registrar on the same.<br>
                                4)	Your agreement must be submitted within 30 days of either party (Licensor/Licensee/Witnesses) executing it, including the date of first execution. If your registration is incomplete (not executed by all parties/ necessary supporting documents not received/ not approved for submission), the biometric activity may have to be repeated, and the cost of the same will have to be borne by the customer.<br>
                                5)	In case of point of contact, navigating all the process of agreement, the incomplete info provided by him/her responsible solely.<br>
                                <br>
                               <b> Visits:</b><br>
                                1)	Our visit fee of Rs.  500 includes expenses incurred to provide high quality team, hardware and software infrastructure to create a wide network, ensure a high degree of reliability and being accessible to our customers all the time.
                               <br> 2)	If our executive is late more than 45 minutes, the customer may cancel the visit and re-schedule it, without incurring extra cost.
                               <br> 3)	Our customers are requested to be present on time at the agreed place of the appointment. We budget 30 minutes for an appointment to be completed. Our executive may be unable to wait beyond 30 minutes at your premises.
                               <br> 4)	One visit consists of our executive visiting a single premise in a city, at the pre-agreed location. If our executive needs to wait at the premises beyond 30 minutes, it will count as an additional visit. Also, if our executive is required to visit an additional premise, it will count as an additional visit, irrespective of the distance between the two premises. Vrentify reserves the right to charge you an additional fee for any such additional visits.
                              <br>  5)	In case of rare instance where a scheduled appointment visit is unsuccessful due to non-accessibility of government’s registration system, we shall provide one additional visit to you at no additional cost.
                               <br> 6)	In case of poor internet connectivity in a given premises for any reason, leading to difficulty in completion of the registration formalities, the customer is expected to co-operate in every way possible to help successful completion of the work.
                               <br> 7)	Our rates for visits have been quoted based on certain assumptions of distance, accessibility by public transport and time of the visit based on the availability of its resources. If the visit address and time do not meet these assumptions, we reserve the right to charge a higher fee for the same/ or offer the customer an option to reschedule the visit to another mutually convenient location.<br><br>
                                
                               <b> Refund and Cancellations:</b><br>
                               <br> 1)	If you wish to cancel an agreement e-registration at any time, due to fingerprint mismatch of the licensor/licensee with AADHAR, or any other issue, a service fee will be payable based on the number of visits completed till then, at the rate of Rs. 500/- per visit.The entire service will be charged, and no refund will be due if a customer makes a cancellation request for any other reason, after a visit is completed.
                              <br>  2)	If stamp duty and registration fees have been paid, you will receive a receipt of the same. Else, you will receive 100% refund of the stamp duty and registration fees amount.
                              <br>  3)	In case of remote registration (where the scanning device is shipped at customer’s location for completion of biometric verification), if the registration is cancelled by the customer for any reason, or is unsuccessful due to mismatch of the customer’s fingerprint with  aadhar, the entire fee will be payable to Vrentify.
                               <br> 4)	We reserve the right to refuse service to customer.
                              <br>  5)	Refund requests, if any, will be processed electronically, within 7 working days after the refund request is approved.
                                
                                
                                
                                
                                </p>
                        </div>
                    </div>
                      
                </div>
              

            </div>
        </div>
    </div>
</section>
<!-- end feature section -->




