 <div id="home" class="carousel slide" data-ride="carousel" >
  <div class="carousel-inner">
    <div class="carousel-item " >
      <img src="assets/images/ecommerce/rentagreement1.png" class="d-block w-100" alt="...">
      <div class="carousel-caption" >
         <a href="#myModal" class="trigger-btn  magn  " data-toggle="modal" id="myModelBtn"><h3 class="text-white btn-default radius-20 my-2 my-sm-0 py-2">Create New Agreement</h3></a>   

      </div>
    </div>
     <div class="carousel-item active">
      <img src="assets/images/ecommerce/go4.png" class="d-block w-100 " alt="...">
      <div class="carousel-caption " >
        <a href="#myModal" class="trigger-btn  magn " data-toggle="modal" id="myModelBtn"><h3 class="text-white btn-default radius-20 my-2 my-sm-0 py-2">Create New Agreement</h3></a>   
      </div>
    </div>
    <div class="carousel-item ">
      <img src="assets/images/ecommerce/adharatm2.png"  class="d-block w-100" alt="...">
      <div class="carousel-caption " >
        <a href="#myModal" class="trigger-btn  magn " data-toggle="modal" id="myModelBtn"><h3 class="text-white btn-default radius-20 my-2 my-sm-0 py-2 ">Create New Agreement</h3></a>   
      </div>
    </div>
  </div>
  <a class="carousel-control-prev" href="#home" role="button" data-slide="prev" >
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#home" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true" ></span>
    <span class="sr-only">Next</span>
  </a>
</div>
<!-- Modal HTML -->
<div id="myModal" class="modal fade">
	<div class="modal-dialog modal-login">
		<div class="modal-content">
			<div class="modal-header">				
				<h4 class="modal-title">Sign In</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
			</div>
			<div class="modal-body">
				<form action="/examples/actions/confirmation.php" method="post">
					<div class="form-group">
						<div class="input-group">
							<span class="input-group-addon"><i class="fa fa-user"></i></span>
							<input type="text" class="form-control" name="username" placeholder="Username" required="required">
						</div>
					</div>
					<div class="form-group">
						<div class="input-group">
							<span class="input-group-addon"><i class="fa fa-lock"></i></span>
							<input type="text" class="form-control" name="password" placeholder="Password" required="required">
						</div>
					</div>
					<div class="form-group">
						<button type="submit" class="btn btn-primary btn-block btn-lg">Sign In</button>
					</div>
					<p class="hint-text"><a href="#">Forgot Password?</a></p>
				</form>
			</div>
			<div class="modal-footer">Don't have an account? <a href="#">Create one</a></div>
		</div>
	</div>
</div>     
