<section>
    <div class="container">
        <div class="row">
            <div class="col-lg-10 offset-lg-1 m1 ">
            <a href="#clients" class="btn primary-btn b1 radius-20  btn-default "  style="background-color: #FFA500; ">View All</a> 
            <a href="https://www.facebook.com/pg/vrentify/reviews/?ref=page_internal;" target="_blank" class="btn primary-btn  btn-default radius-20" style="background-color: #FFA500;">Facebook Reviews</a>
            <a  target="_blank" href="https://www.justdial.com/Nagpur/Registration-Of-Rent-Agreement-In-Nagpur-Mumbai-Pune-Nashik-By-Vrentify-Trimurti-Chowk-Gayatri-Nagar/0712PX712-X712-171003121011-F6U2_BZDET/reviews " class="btn primary-btn  btn-default radius-20" style="background-color: #FFA500;">JustDial Reviews</a>
            <a href="https://www.google.com/search?source=hp&ei=JRGWXOj2D4jIvgSLuruwBw&q=vrentify&btnK=Google+Search&oq=vrentify&gs_l=psy-ab.3..35i39l2j38.777.11327..11726...0.0..0.181.1129.0j9......0....1..gws-wiz.....0..0j0i131j0i131i67j0i67j0i10j0i30.4KVQhn93m7o#btnK=Google%20Search&lrd=0x3bd4bf7e607dd23b:0xa1cfeea4cf02e2e0,1,,, "
             class="btn b2 primary-btn  btn-default radius-20" target="_blank" style="background-color: #FFA500;">Google Reviews</a>
        </div>
    </div>
    </div>
</section>