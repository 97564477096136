import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import{Observable} from 'rxjs/observable';
import{ Post } from 'src/app/post';
import { PostserviceService } from 'src/app/postservice.service';
@Component({
  selector: 'app-blog-list',
  templateUrl: './blog-list.component.html',
  styleUrls: ['./blog-list.component.css']
})
export class BlogListComponent implements OnInit {
  Title:string="Blog List";
  posts: Observable<Post[]>
  constructor(private postservice:PostserviceService,private router:Router) { }

  ngOnInit() {
    this.posts=this.postservice.getPosts();
    // console.log(this)
  }

  delete(id:string)
  {
    this.postservice.delete(id);
  }
  }

