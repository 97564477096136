import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { NavComponent } from './nav/nav.component';
import { RootComponent } from './root/root.component';
import { ApiConfigService } from '../Service/api-config.service';
import { AuthrizationService } from '../Service/auth.service';
import { MainService } from '../Service/main.service';
import { AuthGuard } from '../Service/auth-guard.service';
import { BrowserModule } from '@angular/platform-browser';
import { HttpModule } from '@angular/http';


@NgModule({
  declarations: [NavComponent, RootComponent],
  imports: [
    CommonModule,
    DashboardRoutingModule

  ],
  providers:[
    AuthGuard,AuthrizationService,MainService,ApiConfigService
  ]
})
export class DashboardModule { }
