import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
 
@Component({
  selector: 'app-happyclient',
  templateUrl: './happyclient.component.html',
  styleUrls: ['./happyclient.component.css']
})
export class HappyclientComponent implements OnInit {

 
  constructor(private modalService: NgbModal) { }

  ngOnInit() {}
  testimonialcarousel3 = [
    { img: "assets/images/app_landing2/team/4.Dr.Amit Pratap.jpg",
      name: "Dr. Amit Pratap",
      designation: "Project Accounting Analyst\r\n|\r\n MWH  Resource Net\r\n|\r\n India Pvt Ltd.\r\n| Pune",
      description: " We are highly satisfied with the prompt and timely service. Really it has made our job easy. Thank you for all possible assistance. "
    },
    {
      img: "assets/images/app_landing2/team/Manicon.jpg",
      name: "Raji Loknathan",
      designation: "Doctor|Planet Earth|Mumbai",
      description: "It was really a pleasure and a nice experience to have the rental agreement Regd at home without the hassles of visiting a Regn. Office. We \r\n wish you all the best and would be considering Vrentify for all our future rental agreement requirements."
    }, 
    { 
      img: "assets/images/app_landing2/team/4_Omkumar Talokar_Rent Agreement Registration_Happy Client_Vrentify.jpg",
      name: "Omkar Talokar",
      designation: "Asst. Manager (Account) | Phoenix Marketcity (Alliance Spaces Pvt Ltd.) | Pune",
      description:" Extremely happy with the service.Doorstep delivery of registered documents given me more convenient option in this fast paced world, within 24 hours.I got my registered agreement.And, mesmerized by your customer handling approach. Will definitely recommend to everyone. "
    },
    {
      img: "assets/images/app_landing2/team/Manicon.jpg",
      name: "Kailash G. Rathi",
      designation: "District Judge | High Court| Nagpur",
      description:"  I have for the first time availed E-Registration facility by way of Leave and License Agreement of the property which is located at another place. Without any complication or delay the document was registered and I am fully satisfied with the services provided by Team Vrentify. This is really good for those who are unable to go to registration office due to busy schedule. Recommended."
    },
    { 
      img: "assets/images/app_landing2/team/N.S.Raman.jpg",
      name: "N. S. Raman",
      designation: "Principal Scientist| Deputy Director | NEERI | Nagpur",
      description:"Surely the most awesome service I received in recent times. I executed registration of rent agreement, simply being at my cushions on Sunday morning, yes, I have not visited any government office for same. Team Vrentify take care of everything right from draft to delivery as they say. Super-simple and hassle-free service for sure. Highly Recommended. Kudos Team. Keep it up! "
    },
    { 
      img: "assets/images/app_landing2/team/Manicon.jpg",
      name: "Sanjay Wankhede",
      designation: "Delivery Manager | Persistent Systems Limited | Nagpur",
      description:"  For my property rented in Pune, I registered my leave and license agreement right here from my hometown in Nagpur. Literally, I registered it over Whatsapp only. Communication with them is such simple. Would sum-up their service in 4 P’s: Prompt, Proactive, Polite, Professional and believe me, it’s an ABSOLUTE hassle-free experience. Kudos team Vrentify. Highly recommended!  "
    }
  ]
  teamcarouselOptions3= {
    items: 5,
    margin: 30,
    autoHeight: false,
    nav: false,
    dots:false,
    autoplay: true,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true,
    responsive: {
        0: {
            items: 1
        },
        576: {
            items: 2,
            margin: 10
        },
        991: {
            items: 3,
            margin: 10
        }
    }
  }
}
