import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-rentdocformat',
  templateUrl: './rentdocformat.component.html',
  styleUrls: ['./rentdocformat.component.css']
})
export class RentdocformatComponent implements OnInit {

  constructor( private modalService: NgbModal ) { }
  ngOnInit() {
  }
  openVerticallyCentered(content) {
    this.modalService.open(content, { centered: true, size: 'lg' });
  }

}
