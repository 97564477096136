import { Injectable } from '@angular/core';
import{AngularFirestore,AngularFirestoreCollection,AngularFirestoreDocument} from '@angular/fire/firestore';
import{ Post } from './post';
import 'rxjs/add/operator/map'
import * as firebase from 'firebase';
@Injectable({
  providedIn: 'root'
})
export class PostserviceService {
postsCollection:AngularFirestoreCollection<Post>
postDoc:AngularFirestoreDocument<Post>
  showData: any;

  
    constructor(private afs:AngularFirestore) {
      this.postsCollection=this.afs.collection('blogs',ref=>ref.orderBy('CDate','desc'))
     }
    getPosts()
    {
      return this.postsCollection.snapshotChanges().map(actions=> {
        return actions.map(a=>{
          const data=a.payload.doc.data() as Post
           const id=a.payload.doc.id 
          return{id,...data}
          // return firebase.database().ref('posts').orderByChild("Startdate").limitToFirst(2);
        })
        })
      }
  
      // GetLimitedPost()
      // {
      //   const db = firebase.firestore()
      // db.collection("posts").limit(2).get().then(snap => {
      //     snap.forEach(doc => {
      //       this.showData.push(doc); 
      //       const newdata= this.showData.data()
      //       const id= this.showData.id
      //       return{id,...newdata}
      //         // console.log(doc.data());
      //     });
      // });
    
      // }
  
      getPostData(id :string)
      {
        this.postDoc=this.afs.doc<Post>(`blogs/${id}`)
        return this.postDoc.valueChanges()
      }
  
      create(data:Post)
      {debugger
        this.postsCollection.add(data)
      }
  
      getPost(id:string)
      {
        return this.afs.doc<Post>(`blogs/${id}`)
      }
      delete(id:string)
      {
        return this.getPost(id).delete();
      }
  
      update(id:string,formdata)
      {
        return this.getPost(id).update(formdata);
      }
    }
  
  
   