import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CalculatorModal } from '../calculator-modal';
import { MainServiceService } from '../Service/main-service.service';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.css']
})
export class CalculatorComponent implements OnInit {
  
  public Form: FormGroup;
  my_varemail: { 'EmailTo': any; 'EmailBody': any; 'EmailSubject': string; 'isAttachment': string; 'FileName': any; };
  sum: any;
  sum1: any;
  sum2: any;
  sum3: any;
  keycode: any;
  a: any;
  b: any;
  c: any;
  d: any;
  e: any;
  i: any;
  j: any;
  h: any;
  g: any;
  disableButton: boolean = false;
  stampDuty: any;
  term1toMonth: any;
  term2toMonth: any;
  term3toMonth: any;
  term4toMonth: any;
  term5toMonth: any;
  rent: any;
  term1: any;
  term1Month: any;
  percent: any;
  percent1: any;
  percent2: any;
  percent3: any;
  percentchange: any;
  percentchange1: any;
  percentchange2: any;
  percentchange3: any;
  percentchange4: any;
  radioUrbRular: any;
  public show: boolean = false;
  public show1: boolean = true;
  public show2: boolean = false;
  public show3: boolean = false;
  public show4: boolean = false;
  public show5: boolean = false;
  public isvalid: boolean = false;
  citySelected_default : number =0;
  errors: any;
  result: any;
  //Array For City 
  cities: CalculatorModal[] = [
      // { id: 0, cityName: 'Select Your City'},
      { id: 1, cityName: 'Akola -- AKG' },
      { id: 2, cityName: 'Amravati -- AMT' },
      { id: 3, cityName: 'Aurangabad  --ARB' },
      { id: 2, cityName: 'Kolhapur -- KVD' },
      { id: 3, cityName: 'Latur -- LTR' },
      { id: 4, cityName: 'Beed -- BHR' },
      { id: 5, cityName: 'Bhandara -- BDD' },
      { id: 6, cityName: 'Buldhana -- BDN' },
      { id: 7, cityName: 'Chandrapur -- CND' },
      { id: 8, cityName: 'Dhule -- DUL' },
      { id: 9, cityName: 'Gadchiroli --GCL' },
      { id: 10, cityName: 'Gondia -- GON' },
      { id: 11, cityName: 'Hingoli -- HGL' },
      { id: 12, cityName: 'Jalgaon -- JLG' },
      { id: 13, cityName: 'Jalna -- JLN' },
      { id: 14, cityName: 'Kolhapur -- KVD' },
      { id: 15, cityName: 'Latur -- LTR' },
      { id: 16, cityName: 'Mumbai City -- BOM' },
      { id: 17, cityName: 'Mumbai Suburban -- AOB' },
      { id: 18, cityName: 'Nagpur -- NGP' },
      { id: 19, cityName: 'Nanded -- NAD' },
      { id: 20, cityName: 'Nashik -- NSK' },
      { id: 21, cityName: 'Palghar -- PLG' },
      { id: 22, cityName: 'Parbhani --PBN' },
      { id: 23, cityName: 'Pune -- PND' },
      { id: 24, cityName: 'Raigad -- ALD' },
      { id: 25, cityName: 'Satara -- STR' },
      { id: 26, cityName: 'Solapur -- SLR' },
      { id: 27, cityName: 'Thane -- THN' },
      { id: 28, cityName: 'Nagar (Ahmednagar) -- AMR' },
      { id: 29, cityName: 'Nandurbar -- NBR' },
      { id: 30, cityName: 'Ratnagiri -- RTN' },
      { id: 31, cityName: 'Sangli -- SND' },
      { id: 32, cityName: 'Sindhudurg -- SDD' },
      { id: 33, cityName: 'Usmanabad -- OSB' },
      { id: 34, cityName: 'Vardha -- WRD' },
      { id: 35, cityName: 'Washim -- WSM' },
      { id: 36, cityName: 'Yavatmal -- YTM' }
    ];

  constructor(private fb: FormBuilder, private mainService: MainServiceService) { }
  ngOnInit() {
    this.Form = this.fb.group({
      location: ['', Validators.required],
      radRentType1: [''],
      radRentType2: [''],
      radRentType: [''],
      txtForPeriods: ['', Validators.required],
      txtRefundableSecurityDeposites: ['', Validators.required],
      NonRefund: ['', Validators.required],
      Variable: ['', Validators.required],
      txtMonthlyRent: ['', Validators.required],
      FromMonth1: ['', Validators.required],
      ToMonth1: ['', Validators.required],
      Month1Rental: ['', Validators.required],
      FromMonth2: ['', Validators.required],
      ToMonth2: ['', Validators.required],
      Month1Rental2: ['', Validators.required],
      Percent2: ['10.0', Validators.required],
      FromMonth3: ['', Validators.required],
      ToMonth3: ['', Validators.required],
      Month1Rental3: ['', Validators.required],
      Percent3: ['', Validators.required],
      FromMonth4: ['', Validators.required],
      ToMonth4: ['', Validators.required],
      Month1Rental4: ['', Validators.required],
      Percent4: ['', Validators.required],
      FromMonth5: ['', Validators.required],
      ToMonth5: ['', Validators.required],
      Month1Rental5: ['', Validators.required],
      Percent5: ['', Validators.required],
      UserMobile: ['', [Validators.required, Validators.pattern('^[789][0-9]{9}')]],
      emails: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$')]],
    });
   
  }

  //for Varing Rent ;It  will Display TextBoxes
  toggle(event) {
    this.term1 = 1;
    this.term1Month = 11;
    if (this.Form.value.txtForPeriods == "") {
      document.getElementById("txtForPeriods").focus();
    }
    if (this.Form.value.txtForPeriods > 0 && this.Form.value.txtForPeriods < 61) {
      if (event.target.id == "Variable") {
        this.show = true;//display Text Boxes
        this.show1 = false;//hides Rent Amount Textbox
        this.a = this.term1Month;
        this.b = 1;
        this.sum = +this.a + +this.b;
        this.Form.value.FromMonth2 = this.sum;
        //set 60 as default for term2(to Month)
        this.a = this.Form.value.ToMonth2;
        this.b = this.Form.value.txtForPeriods;
        this.term2toMonth = +(this.b + -this.a) + +this.a;
        this.percentchange = 10.0;
        this.percentchange1 = 10.0;
        this.percentchange2 = 10.0;
        this.percentchange3 = 10.0;
      }
      else {
        this.show = false;//hides controls
        this.show1 = true;//Shows Rent Amount Textbox
      }
    }
    else {
      document.getElementById('Fixed').click();
      this.reset(event);//REset Form
    }
  }

  //Mobile No VAlidation
  PhoneValidate(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }


  //Sets value for Urban
  handleChange1(event) {
    this.radioUrbRular = 1000;
  }
  //Sets value for RUral
  handleChange2(event) {
    this.radioUrbRular = 500;
  }

  //Sets new increamented Value to Next Textbox(ToMonth And From Month)
  increment($event) {
    if (this.Form.value.ToMonth1 > this.Form.value.FromMonth1 && this.Form.value.ToMonth1 <= this.Form.value.txtForPeriods) {
      this.a = this.Form.value.ToMonth1;
      this.b = 1;
      this.sum = +this.a + +this.b;//sum of

    }
  }
  increament2(event) {
    if (this.Form.value.ToMonth2 > this.Form.value.FromMonth2 && this.Form.value.ToMonth2 <= this.Form.value.txtForPeriods) {
      this.a = this.Form.value.ToMonth2;
      this.b = 1;
      this.sum1 = +this.a + +this.b;

    }
    else {
      this.show2 = false;
    }
  }
  increament3(event) {
    if (this.Form.value.ToMonth3 > this.Form.value.FromMonth3 && this.Form.value.ToMonth3 <= this.Form.value.txtForPeriods) {
      this.a = this.Form.value.ToMonth3;
      this.b = 1;
      this.sum2 = +this.a + +this.b;

    }
  }
  increament4(event) {
    if (this.Form.value.ToMonth4 > this.Form.value.FromMonth4 && this.Form.value.ToMonth4 <= this.Form.value.txtForPeriods) {
      this.a = this.Form.value.ToMonth4;
      this.b = 1;
      this.sum3 = +this.a + +this.b;

    }
  }


  SetSixtyToMonth3(event: any) {
    this.a = this.Form.value.ToMonth2
    this.b = this.Form.value.txtForPeriods;
    this.term3toMonth = +(this.b + -this.a) + +this.a;
  }
  SetSixtyToMonth4(event: any) {
    //set 60 as default
    this.a = this.Form.value.ToMonth3
    this.b = this.Form.value.txtForPeriods;
    this.term4toMonth = +(this.b + -this.a) + +this.a;
    //alert(this.term2toMonth)
  }
  SetSixtyToMonth5(event: any) {
    //set 60 as default
    this.a = this.Form.value.ToMonth4
    this.b = this.Form.value.txtForPeriods;
    this.term5toMonth = this.Form.value.txtForPeriods;
    //alert(this.term2toMonth)
  }
  //Validate Months For Max 60 and Min 1
  monthsvalidation(e) {
    // debugger;
    var val = this.Form.value.txtForPeriods;
    if (val > 60 || val <= 0) {
      alert("Property Rented for Months should be less than 60 and More than 0")
      this.Form.get('txtForPeriods').reset();
    }
  }

  //Minus sing Validation, It will not allowed to press minus key
  restrictMinus(e) {
    e = <KeyboardEvent>event;
    if (e.keyCode == 45 || e.keyCode == 46) {
      e.preventDefault();
    }
  }

  restrictupdate1(event) {
    if (this.Form.value.ToMonth1 > 12) {
      this.term1toMonth = this.Form.value.txtForPeriods;
    }
  }
  restrictupdate2(event) {
    if (this.Form.value.ToMonth2 > this.Form.value.txtForPeriods) {
      this.term2toMonth = this.Form.value.txtForPeriods;
    }
  }
  restrictupdate3(event) {
    if (this.Form.value.ToMonth3 > this.Form.value.txtForPeriods) {
      this.term3toMonth = this.Form.value.txtForPeriods;
    }
  }
  restrictupdate4(event) {
    if (this.Form.value.ToMonth4 > this.Form.value.txtForPeriods) {
      this.term4toMonth = this.Form.value.txtForPeriods;
    }
  }
  restrictupdate5(event) {
    if (this.Form.value.ToMonth5 != this.Form.value.txtForPeriods) {
      this.term5toMonth = this.Form.value.txtForPeriods;

    }
  }

  onKey(event: any) {
    if (event.target.id == "ToMonth1") {
      this.increment(event);
    }
    if (event.target.id == "ToMonth2") {
      this.increament2(event);
      this.SetSixtyToMonth3(event);
      if (this.Form.value.ToMonth2 != this.Form.value.txtForPeriods && this.Form.value.ToMonth2 > this.Form.value.FromMonth2) {
        this.show2 = true;
      }
      else {
        this.show2 = false;
        this.show3 = false;
        this.show4 = false;
      }
    }
    if (event.target.id == "ToMonth3") {
      this.increament3(event);
      this.SetSixtyToMonth4(event);
      if (this.Form.value.ToMonth3 != this.Form.value.txtForPeriods && this.Form.value.ToMonth3 > this.Form.value.FromMonth3) {
        this.show3 = true;
      }
      else {
        this.show3 = false;
        this.show4 = false;
      }
    }
    if (event.target.id == "ToMonth4") {
      this.increament4(event);
      this.SetSixtyToMonth5(event);
      if (this.Form.value.ToMonth4 != this.Form.value.txtForPeriods && this.Form.value.ToMonth4 > this.Form.value.FromMonth4) {
        this.show4 = true;
      }
      else {
        this.show4 = false;
      }
    }
  }

  rentForMonths(event) {
    // debugger;
    this.rent = this.Form.value.Month1Rental;
    this.d = this.Form.value.Month1Rental;
    this.b = this.Form.value.Percent2;
    this.percent = (+((this.b * this.d) / 100) + +this.d).toFixed();
    this.Form.controls['Month1Rental2'].setValue(this.percent);
    if (this.Form.value.Month1Rental2 = ! "") {
      this.Form.value.Month1Rental2 = this.percent;
      this.calculatePercent2(event)
    }
    if (this.Form.value.Month1Rental3 = ! "") {
      this.Form.value.Month1Rental3 = this.percent1;

      this.calculatePercent3(event);
    }
    if (this.Form.value.Month1Rental4 = ! "") {
      this.Form.value.Month1Rental4 = this.percent2;
      this.calculatePercent4(event);
    }
  }
  calculatePercent2(event) {
    // debugger;
    this.a = this.Form.value.Month1Rental2;
    this.e = this.Form.value.Percent3;
    this.percent1 = (+((this.e * this.a) / 100) + +this.a).toFixed();
    this.Form.controls['Month1Rental3'].setValue(this.percent1);
  }
  calculatePercent3(event) {
    this.h = this.Form.value.Month1Rental3;
    this.g = this.Form.value.Percent4;
    this.percent2 = (+((this.g * this.h) / 100) + +this.h).toFixed();
    this.Form.controls['Month1Rental4'].setValue(this.percent2);
  }
  calculatePercent4(event) {
    this.i = this.Form.value.Month1Rental4;
    this.j = this.Form.value.Percent5;
    this.percent3 = (+((this.j * this.i) / 100) + +this.i).toFixed();
    this.Form.controls['Month1Rental5'].setValue(this.percent3);
  }//for percent OutPut
  ChangeMonths() {
    this.calculatePercent2(event);
    this.calculatePercent3(event);
    this.calculatePercent4(event);
  }
  ChangeMonths1() {
    this.calculatePercent3(event)
    this.calculatePercent4(event)
  }
  ChangeMonths2() {
    this.calculatePercent4(event)
  }
  changePercent() {
    var newVal = this.Form.value.Percent2;
    this.Form.controls['Percent3'].setValue(newVal);
    this.Form.controls['Percent4'].setValue(this.Form.value.Percent3);
    this.Form.controls['Percent5'].setValue(this.Form.value.Percent4);
    this.rentForMonths(event);
  }
  changePercent1() {
    this.calculatePercent2(event);
    this.Form.controls['Percent4'].setValue(this.Form.value.Percent3);
    this.calculatePercent3(event)
    this.Form.controls['Percent5'].setValue(this.Form.value.Percent4);
    this.calculatePercent4(event)
  }

  changePercent2() {
    // debugger;
    this.calculatePercent3(event)
    this.Form.controls['Percent5'].setValue(this.Form.value.Percent4);
    this.calculatePercent4(event)
  }
  ChangeVal() {
    var Decrease = this.Form.value.Month1Rental2 - this.Form.value.Month1Rental;
    var Decreaseper = ((Decrease / this.Form.value.Month1Rental) * 100).toFixed(4);
    this.Form.controls['Percent2'].setValue(Decreaseper);
    this.changePercent();
  }
  ChangeVal1() {
    // debugger;
    var Decrease = this.Form.value.Month1Rental3 - this.Form.value.Month1Rental2;
    // alert(Decrease)
    var Decreaseper = ((Decrease / this.Form.value.Month1Rental2) * 100).toFixed(4);
    //alert(Decreaseper)
    this.Form.controls['Percent3'].setValue(Decreaseper);
    this.changePercent1();
  }

  ChangeVal2() {
    // debugger;
    var Decrease = this.Form.value.Month1Rental4 - this.Form.value.Month1Rental3;
    var Decreaseper = ((Decrease / this.Form.value.Month1Rental3) * 100).toFixed(4);
    this.Form.controls['Percent4'].setValue(Decreaseper);
    this.changePercent2();
  }

  Percentchange($event) {
    var Decrease = this.Form.value.Month1Rental5 - this.Form.value.Month1Rental4;
    var Decreaseper = ((Decrease / this.Form.value.Month1Rental4) * 100).toFixed(4);
    this.Form.controls['Percent5'].setValue(Decreaseper);
  }
  calculateResult() {
    // debugger;
    const get_idF = document.getElementById('Fixed') as HTMLInputElement;
    const get_idR = document.getElementById('Rural') as HTMLInputElement;
    const get_idV = document.getElementById('Urban') as HTMLInputElement;

    if (this.Form.value.txtForPeriods == "" || this.Form.value.txtForPeriods == null) {
      alert("Enter Property Rented for Months");
      document.getElementById("txtForPeriods").focus();
    }  /* Changes:(following section commented) are done as per Clients requirement by Chetna on date 11/11/2019 */
    /* else {
      if (this.Form.value.txtRefundableSecurityDeposites == "" || this.Form.value.txtRefundableSecurityDeposites == null) {
        alert("Enter Refundable Deposit");
        document.getElementById("txtRefundableSecurityDeposites").focus();
      } */
      else {
        if (this.Form.value.UserMobile == "" || this.Form.value.UserMobile == null) {
          alert("Enter Mobile Number");
          document.getElementById("UserMobile").focus()
        }
        else {
          if (this.Form.value.emails == "" || this.Form.value.emails == null) {
            alert("Enter your Email Id");
            document.getElementById("emails").focus()
          }
          else {
            if (get_idR.checked == true) {
              this.radioUrbRular = 500;
            }
            else {
              this.radioUrbRular = 1000;
            }
            if (get_idF.checked == true) {
              if (this.Form.value.txtMonthlyRent == "" || this.Form.value.txtMonthlyRent == null) {
                alert("Enter Rent Amount");
                document.getElementById("txtMonthlyRent").focus();
              }
              else {
                var d = this.Form.value.NonRefund;
                this.result = ((((this.Form.value.txtForPeriods * this.Form.value.txtMonthlyRent) +
                  (this.Form.value.txtForPeriods / 12) * (this.Form.value.txtRefundableSecurityDeposites / 10)) + d) / 400).toFixed(2);
                if (this.result < 100) {
                  this.stampDuty = 100;
                } else {
                  this.stampDuty = this.result;
                }
                this.show5 = true;//Display Stamp duty And Registration Fees
                this.disableButton = true;//Disable Calculate Button
                localStorage.setItem('stamp', this.stampDuty);
                localStorage.setItem('regFee', this.radioUrbRular);
              }
            }
            else {
              // debugger;
              if (this.show2 != true) {
                this.Form.value.FromMonth3 = "";
                this.Form.value.ToMonth3 = "";
                this.Form.value.Month1Rental3 = "";
                this.Form.value.Percent3 = "";
              }
              if (this.show3 != true) {
                this.Form.value.FromMonth4 = "";
                this.Form.value.ToMonth4 = "";
                this.Form.value.Month1Rental4 = "";
                this.Form.value.Percent4 = "";
              }
              if (this.show4 != true) {
                this.Form.value.FromMonth5 = "";
                this.Form.value.ToMonth5 = "";
                this.Form.value.Month1Rental5 = "";
                this.Form.value.Percent5 = "";
              }
              var p = this.Form.value.txtForPeriods;
              //alert( p+'months')
              var a1 = this.Form.value.Month1Rental;
              //alert( a1 +'rental1')
              var a2 = this.Form.value.Month1Rental2;
              //alert(a2 +'rental2')
              var a3 = this.Form.value.Month1Rental3;
              // alert(a3 +'rental3')
              var a4 = this.Form.value.Month1Rental4;
              //alert(a4 +'rental4')
              var a5 = this.Form.value.Month1Rental5;
              // alert(a5 +'rental5')
              var t1 = this.Form.value.FromMonth1;
              //alert(t1 + 'FromMonth1')
              var t2 = this.Form.value.FromMonth2;
              //  alert(t2 +'FromMonth2')
              var t3 = this.Form.value.FromMonth3;
              // alert( t3 +'FromMonth3')
              var t4 = this.Form.value.FromMonth4;
              //  alert(t4 +'FromMonth4')
              var t5 = this.Form.value.FromMonth5;
              //  alert(t5 +'FromMonth5')
              var mul1 = a1 * 12;
              // alert(mul1 +'mul1')
              var mul2 = a2 * (t2 - t1);
              //  alert(mul2  +'mul2')
              var mul3 = a3 * (t3 - t2);
              //  alert(mul3  +'mul3')
              var mul4 = a4 * (t4 - t3);
              //  alert(mul4  +'mul4')
              var mul5 = a5 * (t5 - t4);
              // alert(mul5  +'mul5')
              // debugger;
              var A = ((mul1) + (mul2) + (mul3) + (mul4) + (mul5));
              //alert(A  +'A')
              var B = (this.Form.value.txtRefundableSecurityDeposites * (0.8333 * p)) / 100;
              // alert(B  +'B')
              var C = this.Form.value.NonRefund;
              //alert(C +'C')
              this.show5 = true;//Display Stamp duty And Registration Fees
              this.stampDuty = (Math.ceil((((A + B + C) * 0.25) / 100))).toFixed(2);

              //   alert(this.stampDuty  +'SD')
              this.disableButton = true;//Disable Calculate Button
            }
          }
        }
      }
    }
  
  //Reset calculator
  reset(event) {
    this.disableButton = false;
    this.Form.reset();
    this.show5 = false;
    document.getElementById('Fixed').click();
    this.radioUrbRular = 1000;
    document.getElementById('Residential').click();
    document.getElementById('Urban').click();
    this.show = false;
    this.show1 = true;
    this.show2 = false;
    this.show3 = false;
    this.show4 = false;
    //location = new location[1];
  }

  //Send Mail After Calculations
  SendCalculations() {
    var EmailBodyMsg = 'Stampduty:Rs.' + ' ' + this.stampDuty + '  ' + 'Registration Fee:Rs.' + this.radioUrbRular;//
    var EmailSubjectMsg = "Stamp Duty Calculation for Leave & License Agreement Registration";
    var EmailToId = this.Form.get("emails").value;
    var attapath = "Vrentify.com--Sample_Leave_&_License_Agreement_Format_for_Registration.pdf";
    this.my_varemail = { 'EmailTo': EmailToId, 'EmailBody': EmailBodyMsg, 'EmailSubject': EmailSubjectMsg, 'isAttachment': '0', 'FileName': attapath }
    //alert("Sent Sucessfully")
    return new Promise(resolve => {
      this.mainService.emailMethod(this.my_varemail)
        .subscribe(
          result => {
            resolve(result);
          },
          error => this.errors = error);
    })
  }
}
