<section class="app1 header sticky" style="z-index:2;" >
  <div class="app1-header "  >
    <div class="container">
      <div class="row">
        <nav class="navbar navbar-expand-lg navbar-light text ">
          <a class="navbar-brand" href="#"> <img src="assets/images/logo/v1.png" alt="" class="img-fluid">
          <button class="navbar-toggler "  type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02"  aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
         <span class="navbar-toggler-icon"></span>
          </button></a>
          <div class="collapse navbar-collapse text" id="navbarTogglerDemo02">
          <ul class="navbar-nav mx-auto mt-2 mt-lg-0 " >

          <li class="nav-item active" >
            <a class="nav-link text" routerLink="/home" style="padding: 15px;  " id="mainmenu" >HOME <span class="sr-only">(current)</span> </a>
          </li>     

          <li class="nav-item"  data-toggle="collapse" data-target="#navbarTogglerDemo02"  aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
           <div [routerLink]="['']" fragment="process"> 
                <a class="nav-link text" href="#process" target="_self" style="padding: 15px; " id="mainmenu">
                  PROCESS
               </a> 
           </div>
          </li>

          <li class="nav-item"data-toggle="collapse" data-target="#navbarTogglerDemo02"  aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
            <div [routerLink]="['']" fragment="Calculator">
                <a class="nav-link text " href="#calculator" style="padding: 15px;"  id="mainmenu">
                  CALCULATOR
               </a>
            </div>
         </li>

          <!-- <li class="nav-item">
          <div [routerLink]="['']" fragment="termscondition">
              <a class="nav-link text " href="#termscondition" style="padding: 15px;color:#00abb9;">
                  Terms & Condition
            </a>
          </div>
        </li> -->

      
        <li class="nav-item"  data-toggle="collapse" data-target="#navbarTogglerDemo02"  aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
          <div [routerLink]="['']" fragment="why_vrentify">
              <a class="nav-link text" href="#service"  id="mainmenu">
                 WHY VRENTIFY
              </a>
          </div>

        </li>
        <li class="nav-item"  data-toggle="collapse" data-target="#navbarTogglerDemo02"  aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
           <div [routerLink]="['']" fragment="happy_client">
              <a class="nav-link text" href="#clients"  id="mainmenu">HAPPY CLIENTS</a>
          </div>
        </li>

         <li class="nav-item"  data-toggle="collapse" data-target="#navbarTogglerDemo02"  aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">  
          <div [routerLink]="['']" fragment="Calculator">
             <a class="nav-link text "  id="mainmenu" href="#blog" >
               BLOGS
            </a>
          </div>
          </li>

          <li class="nav-item"  data-toggle="collapse" data-target="#navbarTogglerDemo02"  aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
            <div [routerLink]="['']" fragment="Calculator">
                          <a class="nav-link text"  id="mainmenu" href="#faq" >    
                    FAQ
              </a>
           </div>
        </li>
        </ul>
        
        <form class="form-inline my-2 my-lg-0">
       <a routerLink="/login"> <button class="btn btn-default radius-20 my-2 my-sm-0 py-1 " type="button" style="color:#00abb9;font-size: large;">Login</button></a>
        </form>
        <!-- <div class="col-lg-3 con" >
        <form class="form-inline">
         
          <ul> 
            <h5 class="text">contact Us</h5>
            <li class="">
              <a  class="text" href="https://mail.google.com/mail/?view=cm&amp;fs=1&amp;tf=1&amp;to=vrentifyemail18@gmail.com" ><i class="fa fa-envelope text-white" style="display: inline;" aria-hidden="true" > </i>&nbsp;registration@Vrentify.com </a>
          </li>
        <li class="text-white">
            <a href="#" class="text"><i class="fa fa-phone text-white" aria-hidden="true" style="display: inline;"></i> &nbsp;879-3333-921</a>
        </li>
      
         </ul>
         
            </form>
          </div> -->
          </div>
        </nav>
      </div>
    </div>
  </div>
</section><!-- Navbar section end--> 
       