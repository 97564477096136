<!-- <app-home></app-home>  -->
<!-- feature section-->
<section id="feature" class="saas1 service" id="process" style="margin-top:6rem;">
    <div class="about-chat">
        <div class="container">
            <div class="row">
                <div class=" col-md-12 text-center">
                    <div class="title">
                        <!-- <img src="assets/images/saas1/title-logo.png" alt="title-logo" class="img-fluid"> -->
                        <div class="main-title">
                            <h2 class="font-primary borders text-center color">
                              Disclaimer
                            </h2><br>
                             
                        </div>
                        <hr style=" background-color:#00abb9 ;" >
                        <div >
                          <p> Please note that Vrentify.com does not give any legal advice and consultation, accounting or auditing services. It is neither a Law Firm nor a Chartered Accountancy or Company Secretary Firm. The services provided by Vrentify.com can in no event be construed to be or taken as a substitute or alternative for Legal Advice.  </p><br>
                          <p> Vrentify.com is a website which provides basic Legal compliances like Leave and License Agreement Registration at client’s doorstep through online and offline mechanism. The users of the Website acknowledge and recognize that nothing in the website constitutes legal advice. Vrentify.com does provide informational and educational content on its Website. However, due to changing laws and amendments in the law, we would be unable to guarantee the accuracy or correctness of content provided therein. </p><br>
                          <p> Please be aware that due to the difference in laws across jurisdictions, we do not assure the accuracy and applicability of any legal documents, or other services, in other jurisdictions. Please contact your Lawyer or Chartered Accountant for the same.  </p><br>
                          <p>  The use of this website does not, in any way, create an Attorney-Client Relationship between Vrentify.com, its employees or vendors or associates and any customer.  </p><br>
                          <p> Vrentify is only responsible to facilitate the process of Leave and License Agreement Registration. You should consult an advocate or solicitor before entering into any agreement. Vrentify will not be responsible for any claim arising out of the agreements registered through this process.  </p><br>
                        
                        </div>
                    </div>
                      
                </div>
              

            </div>
        </div>
    </div>
</section>
<!-- end feature section -->




