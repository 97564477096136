import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-actiontime',
  templateUrl: './actiontime.component.html',
  styleUrls: ['./actiontime.component.css']
})
export class ActiontimeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  experts=[
    {
      img:"assets/images/yoga-img/expert/Channel 1_Whatsapp_2.png",
  
      href:'https://api.whatsapp.com/send?phone=918793333921&text=Hi'
    },
    {
      img:"assets/images/yoga-img/expert/Channel 4_Email_Final.png",
      href:'https://mail.google.com/mail/?view=cm&amp;fs=1&amp;tf=1&amp;to=vrentifyemail18@gmail.com'
    },
    {
      img:"assets/images/yoga-img/expert/Channel 3_WebApp_Final.png",
      href:'/login'
      
    },
    {
      img:"assets/images/yoga-img/expert/Channel 5_SMS_Final.png",
  
      href:'sms://+918793333921?body=I%27m%20interested%20in%20your%20product.%20Please%20contact%20me.'
    },
    {
      img:"assets/images/yoga-img/expert/Channel 2_Call_Final.png",
      href:'tel:+087933 33921'
    }
    
  
  ]
 
  expertCarouselOptions={
    center: true,
    autoplay:true,
    loop: true,
    dots: true,
    margin: 10,
    responsive: {
        0: {
            items: 2,
            margin: 10
        },
        400: {
            items: 3
        },
            768: {
             items: 4 
        },
    1200: {
            items: 6
        }  
    }
  }
 
}
