<!-- section start -->
<section class="agency blog-sec blog-sidebar single_blog_item">
    <div class="container">
        <div class="row">
            <div class="col-lg-9" *ngIf="post">
                <div class="blog-block m-b-20">
                    <div class="blog-box">
                        <div class="overflow-hidden">
                            <img class="img-fluid blur-up" src="{{post.MainImg}}" alt="{{post.Title}}"
                                style="width: 70%; height: 80%;">
                        </div>
                    </div>
                </div>
                <div class="article-content">
                    <div class="entry-meta">
                        <ul>
                            <li>
                                <span>Posted By:</span>
                                <a>{{post.Name}} </a>
                            </li>
                        </ul>
                    </div>
                    <h3 style="color:#00abb9;">{{post.Title}}</h3>
                    <p [innerHTML]="post.BlogDescription">{{post.BlogDescription}}</p>

                    <!-- <div class="blog-block m-b-20">
                    <div class="blog-box">
                        <div class="overflow-hidden">
                             <img class="img-fluid blur-up lazyload " src="{{post.MainImg}}"> 
                        </div>
                    </div>
                </div>
                <p [innerHTML]="post.BlogDescription">{{post.BlogDescription}}</p>   -->

                    <!-- <div class="blog-block m-b-20">
                    <div class="blog-box">
                        <div class="overflow-hidden">
                        <img class="img-fluid blur-up lazyload " src="{{post.MainImg}}" 
                            >
                        </div>
                    </div>
                </div> -->

                </div>
            </div>



            <div class="col-lg-3">
                <div class="blog-side">
                    <div>
                        <h5 class="blog-title">popular posts</h5>
                        <div class="sidebar-container">
                            <div class="post-container d-flex" *ngFor="let post of posts | async">
                                <div class="w-35 m-r-25">
                                    <a (click)="Recall(post.id)">
                                        <img src="{{post.MainImg}}" alt="Title" class="img-fluid">
                                        <h5 class="post-head">{{post.Title}}</h5>
                                        <h6 class="date">{{post.CDate}}</h6>
                                        <h4 class="Name">{{post.Name}}</h4>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- section end -->