import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-taptotop',
  templateUrl: './taptotop.component.html',
  styleUrls: ['./taptotop.component.css']
})
export class TaptotopComponent implements OnInit {
    public showScroll: boolean;
    public showScrollHeight = 500;
    public hideScrollHeight = 10;
  
    constructor() { }
  
    ngOnInit() {
    }
  
    onActivate() {
      if (typeof window !== 'undefined') {
        const scrollToTop = window.setInterval(() => {
          const pos = window.pageYOffset;
          if (pos > 0) {
            window.scrollTo(0, pos - 200); // how far to scroll on each step
          } else {
            window.clearInterval(scrollToTop);
          }
        }, 16);
      }
    }
  
    @HostListener('window:scroll', [])
    onWindowScroll() {
      if (typeof window !== 'undefined') {
        if ((window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) > this.showScrollHeight) {
          this.showScroll = true;
        }
        else if (this.showScroll && (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) < this.hideScrollHeight) {
          this.showScroll = false;
        }
      }
    }
  }
  