<section class="app2 team Pd" id="clients">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="title title2 title-inner">
                    <div class="main-title">
                        <h2 class="font-primary borders text-center text-uppercase m-b-0"><span class="color"> Happy Clients</span>
                        </h2>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <owl-carousel-o [options]="teamcarouselOptions3" class="team-slider">
                    <ng-container *ngFor="let team of testimonialcarousel3">
                        <ng-template carouselSlide class="item">
                            <div class="team-container">
                                <img [src]="team.img" alt="" class="img-fluid members">
                                <div class="text-center">
                                    <h5 class="name text-center"><b>{{team.name}}</b></h5>
                                    <h6 class="post text-center ">{{team.designation}}</h6>
                                    <p class="team-para" >{{team.description}}</p>
                                </div>
                            </div>
                        </ng-template>
                        </ng-container>
                        </owl-carousel-o>
            </div>
        </div>
    </div>
</section>
